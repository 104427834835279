import React from "react";

export default function MainLoader() {
  return (
    <div className='flex flex-col items-center'>
      <div className='relative mb-2 w-[150px] h-[7px] rounded-full bg-[#f3f3f3]'>
        <div
          className='absolute  top-0 left-0 w-[10px] rounded-full h-[100%] main-loader'
          style={{
            backgroundImage: "linear-gradient(to right, #2f73b2 7%, #76b4aa 53%, #c0f8a2 76%, #6eb424 99%)"
          }}
        ></div>
      </div>
      <p className='text-base font-semibold'>Hang tight, we almost there</p>
    </div>
  );
}
