import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./UpcomingEventsStyles.css";

import SidebarComponent from "../../SidebarComponent";
import PlannerHeader from "../Header/Header";

import { AppRoutes } from "../../../../utils/AppRoutes";
import SwitchRole from "../../SwitchRole";
import { useMyContext } from "../../../../ContextApi";

const UpcomingEventDetails = () => {
  const [activeTab, setActiveTab] = useState("");
  const { setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = (id: number) => {
    const vendorId = id.toString();
    navigate(AppRoutes.PLANNER.UPCOMING_EVENTS.replace(":vendorId", vendorId));
  };

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='upcoming-event-details-page' onClick={handleClick}>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <div className='upcoming-event-details'>
        {/* <PlannerHeader title='Mummy’s 50th birthday' previousLink={() => handleNavigation(1)} /> */}
        <PlannerHeader title='Mummy’s 50th birthday' />
        <div className='upcoming-event-details-main'>
          <h1>Vendors</h1>
          <div className='upcoming-event-vendors-container'>
            <div className='vendor-box'>
              <p className='vendor-type'>Musician</p>
              <div className='vendor-profile-box' onClick={() => navigate(AppRoutes.PLANNER.VENDOR_DETAILS)}>
                <div className='vendor-image-box'>
                  <img src='/pic1.svg' alt='profile-pic' />
                </div>
                <p>Fatimah Olayiwola</p>
                <img className='vendor-profile-arrow' src='/grey-right-arrow.svg' alt='right-arrow' />
              </div>
            </div>
            <div className='vendor-box'>
              <p className='vendor-type'>Carterer</p>
              <div className='vendor-profile-box' onClick={() => handleNavigation(2)}>
                <div className='vendor-image-box'>
                  <img src='/pic1.svg' alt='profile-pic' />
                </div>
                <p>Abimbola oladele</p>
                <img className='vendor-profile-arrow' src='/grey-right-arrow.svg' alt='right-arrow' />
              </div>
            </div>
            <div className='vendor-box' onClick={() => handleNavigation(3)}>
              <p className='vendor-type'>MC</p>
              <div className='vendor-profile-box'>
                <div className='vendor-image-box'>
                  <img src='/pic1.svg' alt='profile-pic' />
                </div>
                <p>Funmilayo maxwell</p>
                <img className='vendor-profile-arrow' src='/grey-right-arrow.svg' alt='right-arrow' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
};

export default UpcomingEventDetails;
