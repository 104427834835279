import React from "react";
import { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import CalendarVComponent from "../../common/CalendarVComponent";

export default function CalenderPage() {
  const [activeTab, setActiveTab] = useState("calender");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  
  return (
    <div className='vendorpage'>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <CalendarVComponent
        component='vendor'
        isSwitchUserModalOpen={isSwitchUserModalOpen}
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
    </div>
  );
}
