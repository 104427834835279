import React, { FormEvent, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import partyPalDark from "../../../assets/Partypal-dark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useMyContext } from "../../../ContextApi";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { AppRoutes, PlannerRoutes } from "../../../utils/AppRoutes";
import { keyboardKey } from "@testing-library/user-event";
import CountDownComponent from "../../common/CountDownComponent/CountDownComponent";
import Cookies from "js-cookie";
import wrong from "../../../assets/wrong.svg";
import correct from "../../../assets/correct.svg";
import { Spinner } from "../../common/loader";
import MainLoader from "../../common/MainLoader";

const baseUrl = process.env.REACT_APP_API_URL;

const Endpoint = `${baseUrl}/api/users/`;
const Endpoint2 = `${baseUrl}/api/users/search`;
const Endpoint3 = `${baseUrl}/api/auth/signin`;
const Endpoint4 = `${baseUrl}/api/categories`;

interface myObject {
  username: string;
  password: string;
  confirmPassword: string;
  jobCategory: string;
  socialMediaHandle: string;
  homeAddress: string;
  dateRegisterdAsVendor?: string;
}

interface CustomJwtPayload extends JwtPayload {
  user: {
    dateRegisterdAsVendor: string;
    jobCategory: string;
    _id: string;
    currentRole: string;
    email : string
  };
}

const mockEvent: FormEvent<Element> = {
  preventDefault: () => {
    return false;
  },
  nativeEvent: new Event("mockEvent"),
  currentTarget: document.createElement("div"),
  target: document.createElement("div"),
  bubbles: true,
  cancelable: true,
  defaultPrevented: false,
  eventPhase: Event.AT_TARGET,
  isTrusted: true,
  stopPropagation: () => {
    return false;
  },
  timeStamp: Date.now(),
  type: "submit",
  isDefaultPrevented: () => {
    return false;
  },
  isPropagationStopped: () => {
    return false;
  },
  persist: () => {
    return false;
  }
};

export default function SignupComponent() {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const { userDetails, setUserDetails, jobCategories, setJobCategories } = useMyContext();
  const [emailSignup, setEmailsignup] = useState(true);
  const [role, setRole] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerifying2, setIsVerifying2] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpBoxSize, setOtpBoxSize] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpObj] = useState<myObject>({
    username: "",
    password: "",
    confirmPassword: "",
    jobCategory: "",
    socialMediaHandle: "",
    homeAddress: "",
    dateRegisterdAsVendor: ""
  });
  const [email] = useState({
    email: ""
  });
  const [signUpObj2, setSignUpObj2] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const [validationErrorObj, setValidationErrorObj] = useState({
    validateFirstName: false,
    validateLastName: false,
    validatePhoneNumber: false
  });
  const { firstName, lastName, phoneNumber } = signUpObj2;
  const { validateFirstName, validateLastName, validatePhoneNumber } = validationErrorObj;
  const [countdownKey, setCountdownKey] = useState(0);
  const [isCountDownOver, setIsCountDownOver] = useState(false);
  const validationSchema = Yup.object({
    username: Yup.string(),
    password: Yup.string()
      .required("Password is required")
      .matches(/[^a-zA-Z0-9]/, "Must contain at least one special case")
      .matches(/.{8,}/, "Must contain at least 8 letters")
      .matches(/(?=.*[A-Z])/, "Must contain at least one upper case")
      .matches(/(?=.*[a-z])/, "Must contain at least lower case"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password", undefined), undefined] as const, "Passwords must match")
      .required("Confirm Password is required"),
    jobCategory: Yup.string(),
    socialMediaHandle: Yup.string(),
    homeAddress: Yup.string(),
    dateRegisterdAsVendor: Yup.string()
  });
  const emailValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email is required")
  });

  const formik1 = useFormik({
    initialValues: email,
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      handleSubmit1(values);
    }
  });

  const formik = useFormik({
    initialValues: signUpObj,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      finalHandleSubmit(values);
    }
  });

  function handleClick() {
    if (count === 0 && !emailSignup) {
      setEmailsignup(true);
    } else if (role === "planner") {
      if (count === 8) {
        setCount(6);
        return;
      } else if (count === 3) {
        setCount(1);
        return;
      }
    }
    if (count <= 0) {
      navigate(AppRoutes.ONBOARDING);
      return;
    }
    setCount((prev) => {
      return prev - 1;
    });
  }

  async function handleSubmit1(obj: { email: string }) {
    setIsVerifying(true);
    try {
      const response = await axios.post(`${baseUrl}/api/auth/sendotp`, {
        email: obj.email,
        type: "verification"
      });
      setIsVerifying(false);
      if (response.status === 200) {
        toast.success(response.data.message);
        if (count == 0) {
          setCount(1);
        }
        if (isCountDownOver) {
          restartCountdown();
          setIsCountDownOver(false);
        }
      }
    } catch (error: any) {
      setIsVerifying(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  }

  function customHandleSubmit1() {
    formik1.handleSubmit();
  }
  async function handleSubmit2() {
    setIsVerifying2(true);
    try {
      const response = await axios.post(`${baseUrl}/api/auth/verifyotp`, {
        email: formik1.values.email,
        otp: otp,
        type: "verification"
      });
      if (response.status === 200) {
        setIsVerifying2(false);
        setCount(2);
      }
    } catch (error: any) {
      setIsVerifying2(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  }
  function handleSubmit3() {
    if (firstName.length > 0 && lastName.length > 0) {
      setCount(3);
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    if (name === "phoneNumber") {
      setSignUpObj2({
        ...signUpObj2,
        phoneNumber: value.replace(/\D/g, "").substring(0, 11)
      });
    } else {
      setSignUpObj2({
        ...signUpObj2,
        [name]: value.replace(/\d/g, "")
      });
    }
    if (name === "firstName") {
      if (value.length == 0) {
        setValidationErrorObj({
          ...validationErrorObj,
          validateFirstName: true
        });
      } else {
        setValidationErrorObj({
          ...validationErrorObj,
          validateFirstName: false
        });
      }
    } else if (name === "lastName") {
      if (value.length == 0) {
        setValidationErrorObj({
          ...validationErrorObj,
          validateLastName: true
        });
      } else {
        setValidationErrorObj({
          ...validationErrorObj,
          validateLastName: false
        });
      }
    } else {
      if (value.length < 11) {
        setValidationErrorObj({
          ...validationErrorObj,
          validatePhoneNumber: true
        });
      } else {
        setValidationErrorObj({
          ...validationErrorObj,
          validatePhoneNumber: false
        });
      }
    }
  }

  function handleSubmit4(e: React.FormEvent) {
    e.preventDefault();
    if (validatePhoneNumber) return;
    setCount(4);
  }
  function handleSubmit5(e: React.FormEvent) {
    e.preventDefault();
    if (formik.errors.password) return;
    setCount(5);
  }
  function handleSubmit6(e: React.FormEvent) {
    e.preventDefault();
    if (formik.values.password !== formik.values.confirmPassword) return;
    setCount(6);
  }
  function handleSubmit7(e: React.FormEvent) {
    e.preventDefault();
    if (formik.values.jobCategory == "" || formik.values.jobCategory == "--") return;
    setCount(8);
  }
  function handleSubmit8() {
    if (role === "vendor") {
      setCount(2);
    } else if (role === "planner") {
      googleFinalHandleSubmit(mockEvent);
    } else {
      return;
    }
  }
  function handleSubmit9() {
    setCount(3);
  }
  function chooseRole() {
    if (role) {
      if (role === "vendor") {
        setCount(7);
      } else if (role === "planner") {
        formik.handleSubmit();
      } else {
        return;
      }
    }
  }
  function handleNumber(e: React.FormEvent) {
    e.preventDefault();
    setCount(1);
  }

  const continueWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`
        }
      });
      if (data.status === 200) {
        try {
          const response = await axios.get(`${Endpoint2}?email=${data.data.email}`);
          if (response.data.no_of_users == 1) {
            try {
              const response2 = await axios.post(Endpoint3, {
                email: response.data.users[0].email,
                password: `${response.data.users[0].lastName}000001`
              });
              if (response2.data.status == 200) {
                sessionStorage.setItem("token", response2.data.token);
                const expiryDate = new Date();
                expiryDate.setHours(expiryDate.getHours() + 1);
                Cookies.set("token", response2.data.token, { expires: expiryDate });
                const decoded: CustomJwtPayload = jwtDecode(response2.data.token);
                sessionStorage.setItem("_id", decoded.user._id);
                sessionStorage.setItem("email", decoded.user.email)
                Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
                if (decoded.user.dateRegisterdAsVendor) {
                  navigate(AppRoutes.V_DASH);
                } else {
                  navigate(PlannerRoutes.HOME);
                }
              }
            } catch (error: any) {
              if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
              } else if (error.message) {
                toast.error(error.message);
              } else {
                toast.error("An Error Occurred");
              }
            }
          } else {
            setEmailsignup(false);
            setUserDetails({
              ...userDetails,
              email: data.data.email,
              last_name: data.data.family_name,
              first_name: data.data.given_name,
              full_name: data.data.name,
              token: tokenResponse.access_token,
              profilePicture: data.data.picture || ""
            });
          }
        } catch (error: any) {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error("An Error Occurred");
          }
        }
      }
    },
    onError: () => {
      toast.error("Google login failed");
    }
  });

  async function finalHandleSubmit(obj: myObject) {
    setIsLoading(true);

    if (role === "vendor") {
      const payLoad = {
        ...obj,
        email: formik1.values.email,
        jobCategory: formik.values.jobCategory,
        lastName: lastName,
        firstName: firstName,
        phoneNumber: phoneNumber,
        username: firstName,
        homeAddress: "Test",
        dateRegisterdAsVendor: new Date(),
        currentRole: "vendor"
      };
      let response;
      try {
        response = await axios.post(Endpoint, payLoad);
        setIsLoading(false);
        if (response.status === 200) {
          try {
            const response = await axios.post(`${baseUrl}/api/auth/signin`, {
              email: formik1.values.email,
              password: obj.password
            });
            if (response.data.status === 200) {
              const expiryDate = new Date();
              expiryDate.setHours(expiryDate.getHours() + 1);
              Cookies.set("token", response.data.token, { expires: expiryDate });
              sessionStorage.setItem("token", response.data.token);
              const decoded: CustomJwtPayload = jwtDecode(response.data.token);
              sessionStorage.setItem("_id", decoded.user._id);
              sessionStorage.setItem("email", decoded.user.email)
              Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
              navigate(AppRoutes.V_DASH);
            }
          } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("An Error Occurred");
            }
          }

          // toast.success("succefully created account");
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    } else {
      const payLoad = {
        email: formik1.values.email,
        username: firstName,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        confirmPassword: obj.confirmPassword,
        password: obj.password,
        homeAddress: "Test",
        dateRegisterdAsVendor: "",
        currentRole: "planner"
      };
      let response;
      try {
        response = await axios.post(Endpoint, payLoad);
        setIsLoading(false);
        if (response.status === 200) {
          try {
            const response = await axios.post("https://partypal-api.onrender.com/api/auth/signin", {
              email: formik1.values.email,
              password: obj.password
            });
            if (response.data.status === 200) {
              const expiryDate = new Date();
              expiryDate.setHours(expiryDate.getHours() + 1);
              Cookies.set("token", response.data.token, { expires: expiryDate });
              sessionStorage.setItem("token", response.data.token);
              const decoded: CustomJwtPayload = jwtDecode(response.data.token);
              sessionStorage.setItem("_id", decoded.user._id);
              sessionStorage.setItem("email", decoded.user.email)
              Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
              navigate(AppRoutes.PLANNER.HOME);
            }
          } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("An Error Occurred");
            }
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    }
  }

  async function googleFinalHandleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    if (role === "vendor") {
      const payLoad = {
        phoneNumber: phoneNumber,
        socialMediaHandle: formik.values.socialMediaHandle,
        jobCategory: formik.values.jobCategory,
        email: userDetails.email,
        lastName: userDetails.last_name,
        firstName: userDetails.first_name,
        username: userDetails.first_name,
        homeAddress: "test",
        password: `${userDetails.last_name}000001`,
        confirmPassword: `${userDetails.last_name}000001`,
        dateRegisterdAsVendor: new Date(),
        token: userDetails.token,
        currentRole: "vendor",
        profilePicture: userDetails.profilePicture || ""
      };
      let response;
      try {
        response = await axios.post(Endpoint, payLoad);
        setIsLoading(false);
        if (response.status === 200) {
          setCount(4);
          let response2;
          try {
            const signinEnpoint = `${baseUrl}/api/auth/signin`;
            response2 = await axios.post(signinEnpoint, {
              email: payLoad.email,
              password: payLoad.password
            });
            if (response2.status === 200) {
              sessionStorage.setItem("token", response2.data.token);
              const expiryDate = new Date();
              expiryDate.setHours(expiryDate.getHours() + 1);
              Cookies.set("token", response2.data.token, { expires: expiryDate });
              
              const decoded: CustomJwtPayload = jwtDecode(response2.data.token);
              sessionStorage.setItem("_id", decoded.user._id);
              sessionStorage.setItem("email", decoded.user.email)
              Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
              navigate(AppRoutes.V_DASH);
            } else {
              setCount(3);
              toast.error(response2.data.message);
            }
          } catch (error: any) {
            setCount(3);
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("An Error Occurred");
            }
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setIsLoading(false);
        setCount(3);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    } else {
      const payLoad = {
        phoneNumber: phoneNumber,
        email: userDetails.email,
        lastName: userDetails.last_name,
        firstName: userDetails.first_name,
        username: userDetails.first_name,
        homeAddress: "test",
        password: `${userDetails.last_name}000001`,
        confirmPassword: `${userDetails.last_name}000001`,
        dateRegisterdAsVendor: "",
        token: userDetails.token,
        currentRole: "planner",
        profilePicture: userDetails.profilePicture || ""
      };
      let response;
      try {
        response = await axios.post(Endpoint, payLoad);
        setIsLoading(false);
        if (response.status === 200) {
          setCount(4);
          let response2;
          try {
            const signinEnpoint = `${baseUrl}/api/auth/signin`;
            response2 = await axios.post(signinEnpoint, {
              email: payLoad.email,
              password: payLoad.password
            });
            if (response2.status === 200) {
              sessionStorage.setItem("token", response2.data.token);
              const expiryDate = new Date();
              expiryDate.setHours(expiryDate.getHours() + 1);
              Cookies.set("token", response2.data.token, { expires: expiryDate });
        
              const decoded: CustomJwtPayload = jwtDecode(response2.data.token);
              sessionStorage.setItem("_id", decoded.user._id);
              sessionStorage.setItem("email", decoded.user.email)
              Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
              navigate(PlannerRoutes.HOME);
            } else {
              setCount(3);
              toast.error(response2.data.message);
            }
          } catch (error: any) {
            setCount(3);
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("An Error Occurred");
            }
          }
        } else {
          setCount(3);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setIsLoading(false);
        setCount(3);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    }
  }


  function checkOtpBoxSize() {
    if (window.innerWidth <= 425) {
      setOtpBoxSize(true);
    } else {
      setOtpBoxSize(false);
    }
  }

  function handleKeyDown(e: keyboardKey) {
    if (e.key == "Enter") {
      if (count == 0 && emailSignup) {
        formik1.handleSubmit();
      } else if (count == 1 && emailSignup) {
        handleSubmit2();
      } else if (count == 2 && emailSignup) {
        handleSubmit3();
      } else if (count == 3 && emailSignup) {
        handleSubmit4(mockEvent);
      } else if (count == 4 && emailSignup) {
        handleSubmit5(mockEvent);
      } else if (count == 5 && emailSignup) {
        handleSubmit6(mockEvent);
      } else if (count == 6 && emailSignup) {
        chooseRole();
      } else if (count == 7 && emailSignup && role == "vendor") {
        handleSubmit7(mockEvent);
      } else if (count == 8 && emailSignup && role == "vendor") {
        formik.handleSubmit();
      } else if (count == 0 && !emailSignup) {
        handleNumber(mockEvent);
      } else if (count == 1 && !emailSignup) {
        handleSubmit8();
      } else if (count == 2 && !emailSignup && role == "vendor") {
        handleSubmit9();
      } else if (count == 3 && !emailSignup) {
        googleFinalHandleSubmit(mockEvent);
      }
    }
  }

  const restartCountdown = () => {
    setCountdownKey((prevKey) => prevKey + 1);
  };

  const getJobCategories = async (signal: AbortSignal) => {
    try {
      const response = await axios.get(Endpoint4, {
        signal: signal
      });
      if (response.status === 200) {
        setJobCategories(response.data);
      } else {
        setJobCategories([]);
      }
    } catch (error) {
      setJobCategories([]);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [count, otp, formik.values, signUpObj2, role]);
  useEffect(() => {
    checkOtpBoxSize();
    window.addEventListener("resize", checkOtpBoxSize);
    return () => {
      window.removeEventListener("resize", checkOtpBoxSize);
    };
  });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (jobCategories.length === 0) {
      getJobCategories(signal);
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='onboarding start'>
      <Toaster />
      <svg onClick={handleClick} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          d='M16.6195 2.98998C16.5034 2.87357 16.3655 2.78121 16.2136 2.71819C16.0617 2.65518 15.8989 2.62274 15.7345 2.62274C15.5701 2.62274 15.4073 2.65518 15.2554 2.71819C15.1036 2.78121 14.9656 2.87357 14.8495 2.98998L6.53951 11.3C6.44681 11.3925 6.37326 11.5024 6.32308 11.6234C6.2729 11.7443 6.24707 11.874 6.24707 12.005C6.24707 12.1359 6.2729 12.2656 6.32308 12.3866C6.37326 12.5076 6.44681 12.6175 6.53951 12.71L14.8495 21.02C15.3395 21.51 16.1295 21.51 16.6195 21.02C17.1095 20.53 17.1095 19.74 16.6195 19.25L9.37951 12L16.6295 4.74998C17.1095 4.26998 17.1095 3.46998 16.6195 2.98998Z'
          fill='#262626'
        />
      </svg>
      <img src={partyPalDark} alt='logo' />
      {count == 0 && emailSignup ? (
        <>
          <div className='sigup-container'>
            <form onSubmit={formik1.handleSubmit}>
              <label>Create account</label>
              <p className='title'>Your email address</p>
              <input
                type='text'
                placeholder='E-mail address'
                name='email'
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
                value={formik1.values.email}
                className={formik1.touched.email && formik1.errors.email ? "invalid" : "correct"}
              />
              {formik1.touched.email && formik1.errors.email ? <p className='error'>email is required</p> : ""}
              <button 
                type='submit' style={{ gap: "0" }} 
                className={formik1.errors.email ? "intro1-btn disabled" : "intro1-btn"}
                disabled={isVerifying}
                >
                {isVerifying ? (
                  <>
                    <span className='text-xl font-normal'>Verifying...</span>&nbsp;
                    <Spinner />
                  </>
                ) : (
                  <span>Sign up</span>
                )}
              </button>
            </form>
            <div className='signup-divider'>
              <div></div>
              <span>or</span>
              <div></div>
            </div>
            <div onClick={() => continueWithGoogle()} className='google-signup'>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path
                  d='M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
                  fill='#FFC107'
                />
                <path
                  d='M3.15283 7.3455L6.43833 9.755C7.32733 7.554 9.48033 6 11.9998 6C13.5293 6 14.9208 6.577 15.9803 7.5195L18.8088 4.691C17.0228 3.0265 14.6338 2 11.9998 2C8.15883 2 4.82783 4.1685 3.15283 7.3455Z'
                  fill='#FF3D00'
                />
                <path
                  d='M12.0002 22.0001C14.5832 22.0001 16.9302 21.0116 18.7047 19.4041L15.6097 16.7851C14.5719 17.5743 13.3039 18.0011 12.0002 18.0001C9.39916 18.0001 7.19066 16.3416 6.35866 14.0271L3.09766 16.5396C4.75266 19.7781 8.11366 22.0001 12.0002 22.0001Z'
                  fill='#4CAF50'
                />
                <path
                  d='M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
                  fill='#1976D2'
                />
              </svg>
              <span>continue with google</span>
            </div>
            <div
              // onClick={() => {
              //   setCount(5);
              //   setEmailsignup(false);
              // }}
              className='apple-signup'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='21' height='24' viewBox='0 0 21 24' fill='none'>
                <path
                  d='M16.9554 23.0371C15.6406 24.3036 14.2049 24.1036 12.8229 23.5037C11.3605 22.8904 10.0187 22.8638 8.47576 23.5037C6.54368 24.3303 5.52397 24.0903 4.37009 23.0371C-2.17751 16.3313 -1.21147 6.11923 6.22167 5.74594C8.03299 5.83926 9.29421 6.73248 10.3542 6.81247C11.9374 6.49251 13.4535 5.57263 15.1441 5.69261C17.1701 5.85259 18.6997 6.65249 19.706 8.09231C15.5198 10.5853 16.5127 16.0646 20.35 17.5978C19.5852 19.5975 18.5923 21.5839 16.942 23.0504L16.9554 23.0371ZM10.22 5.66595C10.0187 2.69299 12.4473 0.23997 15.238 0C15.6271 3.43957 12.0984 5.99924 10.22 5.66595Z'
                  fill='#262626'
                />
              </svg>
              <span>continue with apple</span>
            </div>
          </div>
        </>
      ) : count == 1 && emailSignup ? (
        <div className='verification-container'>
          <p className='verification-title'>Enter verification code</p>
          <p className='verification-mail'>An otp has been sent to your mail</p>
          <OtpInput
            value={otp}
            containerStyle='otp-container'
            inputStyle={
              !otpBoxSize ? { width: "64px", height: "55px", borderRadius: "4px" } : { width: "40px", height: "31px", borderRadius: "4px" }
            }
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            inputType='tel'
          />
          <p className='verification-otp'>
            <a
              href='javascript:void(0)'
              onClick={() => {
                if (isCountDownOver) {
                  customHandleSubmit1();
                }
              }}
            >
              Resend otp
            </a>{" "}
            in <CountDownComponent isCountDownOver={isCountDownOver} setIsCountDownOver={setIsCountDownOver} countdownKey={countdownKey} />
          </p>
          <button onClick={handleSubmit2} className='intro1-btn'>
            {isVerifying2 ? (
              <>
                <span>Verifying...</span>
                <Spinner />
              </>
            ) : (
              <>
                <span>Continue</span>
                <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                    fill='white'
                  />
                </svg>
              </>
            )}
          </button>
        </div>
      ) : count == 2 && emailSignup ? (
        <div className='username-container'>
          <p className='username-container-title'>Your full name</p>
          <form className='username-form'>
            <input
              type='text'
              placeholder='First name'
              name='firstName'
              onChange={handleChange}
              value={firstName}
              className={validateFirstName ? "invalid" : "correct"}
              required
            />
            <br />
            <input
              type='text'
              placeholder='Last name'
              required
              name='lastName'
              onChange={handleChange}
              value={lastName}
              className={validateLastName ? "invalid" : "correct"}
            />
            {validateFirstName && validateLastName ? <p className='error'>fullname is required</p> : ""}
            <button onClick={handleSubmit3} className='intro1-btn btn'>
              <span>Continue</span>
              <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <path
                  d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                  fill='white'
                />
              </svg>
            </button>
          </form>
        </div>
      ) : count == 3 && emailSignup ? (
        <div className='phoneNumber-container'>
          <p className='phonenumber-title'>Enter phone number</p>
          <form className='phonenumber-form'>
            <input
              type='tel'
              placeholder='080 XXX XXX XX'
              name='phoneNumber'
              onChange={handleChange}
              value={phoneNumber}
              className={validatePhoneNumber ? "invalid" : "correct"}
              required
            />
            {validatePhoneNumber ? <p className='error'>Phone number must be eleven</p> : ""}
            <button
              disabled={validatePhoneNumber || phoneNumber.length < 11 ? true : false}
              onClick={handleSubmit4}
              className='intro1-btn btn'
            >
              <span>Continue</span>
              <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <path
                  d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                  fill='white'
                />
              </svg>
            </button>
          </form>
        </div>
      ) : count == 4 && emailSignup ? (
        <div className='password'>
          <p className='password-title'>Enter password</p>
          <form onSubmit={handleSubmit5}>
            <div className='input-password'>
              <input
                type={!isPasswordVisible ? "password" : "text"}
                placeholder='password'
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={formik.touched.password && formik.errors.password ? "invalid" : "correct"}
                required
              />
              <div
                className='icon-container'
                onClick={() => {
                  setIsPasswordVisible((prev) => !prev);
                }}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <rect width='24' height='24' fill='#F1F5FA' />
                  <path
                    d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
                    fill='#5F5F5F'
                  />
                  <path
                    d='M18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C9.94078 4.5 7.94438 5.10937 6.05484 6.30375C4.14937 7.51078 2.28141 9.70312 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C14.2856 19.5 16.4817 18.7936 18.5184 17.4005C20.3114 16.1719 22.0177 14.0541 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5Z'
                    fill='#5F5F5F'
                  />
                </svg>
                {!isPasswordVisible && <span className='line'></span>}
              </div>
            </div>
            {formik.errors.password && (
              <div className='password-criteria'>
                <div>
                  {!/[^a-zA-Z0-9]/.test(formik.values.password) ? (
                    <img src={wrong} alt='wrong icon' />
                  ) : (
                    <img src={correct} alt='correct icon' />
                  )}
                  <p>Must contain at least one special case</p>
                </div>
                <div>
                  {!/.{8,}/.test(formik.values.password) ? <img src={wrong} alt='wrong icon' /> : <img src={correct} alt='correct icon' />}
                  <p>Must contain at least 8 letters</p>
                </div>
                <div>
                  {!/(?=.*[A-Z])/.test(formik.values.password) ? (
                    <img src={wrong} alt='wrong icon' />
                  ) : (
                    <img src={correct} alt='correct icon' />
                  )}
                  <p>Must contain at least one upper case</p>
                </div>
                <div>
                  {!/(?=.*[a-z])/.test(formik.values.password) ? (
                    <img src={wrong} alt='wrong icon' />
                  ) : (
                    <img src={correct} alt='correct icon' />
                  )}
                  <p>Must contain at least lower case</p>
                </div>
              </div>
            )}
            <button type='submit' className='intro1-btn btn'>
              <span>Continue</span>
              <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <path
                  d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                  fill='white'
                />
              </svg>
            </button>
          </form>
        </div>
      ) : count == 5 && emailSignup ? (
        <div className='password'>
          <p className='password-title'>Confirm password</p>
          <form onSubmit={handleSubmit6}>
            <div className='input-password'>
              <input
                type={!isPasswordVisible ? "password" : "text"}
                placeholder='confirm password'
                name='confirmPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "invalid" : "correct"}
                required
              />
              <div
                className='icon-container'
                onClick={() => {
                  setIsPasswordVisible((prev) => !prev);
                }}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <rect width='24' height='24' fill='#F1F5FA' />
                  <path
                    d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
                    fill='#5F5F5F'
                  />
                  <path
                    d='M18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C9.94078 4.5 7.94438 5.10937 6.05484 6.30375C4.14937 7.51078 2.28141 9.70312 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C14.2856 19.5 16.4817 18.7936 18.5184 17.4005C20.3114 16.1719 22.0177 14.0541 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5Z'
                    fill='#5F5F5F'
                  />
                </svg>
                {!isPasswordVisible && <span className='line'></span>}
              </div>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <p className='error'>password must match</p> : ""}
            <button type='submit' className='intro1-btn btn'>
              <span>Continue</span>
              <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <path
                  d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                  fill='white'
                />
              </svg>
            </button>
          </form>
        </div>
      ) : count == 6 && emailSignup ? (
        <div className='oauth'>
          {/* <p className='oauth-title'>Partypal</p> */}
          <p className='oauth-desc'>Which role suits you best?</p>
          <div className='user-type'>
            <div className={role === "planner" ? "planner selected" : "planner"} onClick={() => setRole("planner")}>
              <div className='user-container'></div>
              <p>Planner</p>
            </div>
            <div className={role === "vendor" ? "vendor selected" : "vendor"} onClick={() => setRole("vendor")}>
              <div className='user-container'></div>
              <p>Vendor</p>
            </div>
          </div>
          <button className='intro1-btn' onClick={chooseRole} disabled={role == "" ? true : false}>
            {isLoading ? (
              <>
                <span>Registering...</span>
                <Spinner />
              </>
            ) : (
              <>
                <span>Continue</span>
                <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                    fill='white'
                  />
                </svg>
              </>
            )}
          </button>
        </div>
      ) : count == 7 && emailSignup && role == "vendor" ? (
        <div className='category'>
          <p className='category-desc'>Choose vendor category</p>
          <select name='jobCategory' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.jobCategory}>
            <option value='--' disabled selected>
              -- choose a category --
            </option>
            {jobCategories.map(({ _id, name }) => {
              return (
                <option key={_id} value={name}>
                  {name}
                </option>
              );
            })}
          </select>
          {formik.errors.jobCategory && formik.touched.jobCategory ? <p className='error'>kindly select a job category</p> : ""}
          <button onClick={handleSubmit7} className='intro1-btn'>
            <span>Continue</span>
            <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
              <path
                d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                fill='white'
              />
            </svg>
          </button>
        </div>
      ) : count == 8 && emailSignup && role == "vendor" ? (
        <div className='instagram-container'>
          <p className='instagram-title'>Enter Instagram handle</p>
          <form onSubmit={formik.handleSubmit} className='phonenumber-form'>
            <input
              type='text'
              placeholder='e.g zaypurple'
              name='socialMediaHandle'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.socialMediaHandle}
              className={formik.touched.socialMediaHandle && formik.values.socialMediaHandle.length <= 0 ? "invalid" : "correct"}
              required
            />
            {formik.touched.socialMediaHandle && formik.values.socialMediaHandle.length <= 0 ? (
              <p className='error'>instagram handle required</p>
            ) : null}
            <button disabled={formik.values.socialMediaHandle.length <= 0 ? true : false} type='submit' className='intro1-btn btn'>
              {isLoading ? (
                <>
                  <span>Registering...</span>
                  <Spinner />
                </>
              ) : (
                <>
                  <span>Continue</span>
                  <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                      d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </form>
        </div>
      ) : null}

      {count == 0 && !emailSignup ? (
        <div className='phoneNumber-container'>
          <p className='phonenumber-title'>Enter phone number</p>
          <form onSubmit={handleNumber} className='phonenumber-form'>
            <input
              type='tel'
              placeholder='080 XXX XXX XX'
              value={phoneNumber}
              name='phoneNumber'
              onChange={handleChange}
              className={validatePhoneNumber ? "invalid" : "correct"}
              required
            />
            {validatePhoneNumber ? <p className='error'>Phone number must be eleven</p> : ""}
            <button type='submit' disabled={validatePhoneNumber || phoneNumber.length < 11 ? true : false} className='intro1-btn btn'>
              <span>Continue</span>
              <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                <path
                  d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                  fill='white'
                />
              </svg>
            </button>
          </form>
        </div>
      ) : count == 1 && !emailSignup ? (
        <div className='oauth'>
          <p className='oauth-desc'>Which role suits you best?</p>
          <div className='user-type'>
            <div className={role === "planner" ? "planner selected" : "planner"} onClick={() => setRole("planner")}>
              <div className='user-container'></div>
              <p>Planner</p>
            </div>
            <div className={role === "vendor" ? "vendor selected" : "vendor"} onClick={() => setRole("vendor")}>
              <div className='user-container'></div>
              <p>Vendor</p>
            </div>
          </div>
          <button disabled={role == "" ? true : false} onClick={handleSubmit8} className='intro1-btn'>
            <span>Continue</span>
            <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
              <path
                d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                fill='white'
              />
            </svg>
          </button>
        </div>
      ) : count == 2 && !emailSignup && role == "vendor" ? (
        <div className='category'>
          <p className='category-desc'>Choose vendor category </p>
          <select value={formik.values.jobCategory} name='jobCategory' onChange={formik.handleChange} onBlur={formik.handleBlur}>
            <option value='--' disabled selected>
              -- choose a category --
            </option>
            {jobCategories.map(({ _id, name }) => {
              return (
                <option key={_id} value={name}>
                  {name}
                </option>
              );
            })}
          </select>
          {formik.touched.jobCategory && formik.errors.jobCategory ? <div>{formik.errors.jobCategory}</div> : null}
          <button onClick={handleSubmit9} className='intro1-btn'>
            <span>Continue</span>
            <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
              <path
                d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                fill='white'
              />
            </svg>
          </button>
        </div>
      ) : count == 3 && !emailSignup && role == "vendor" ? (
        <div className='instagram-container'>
          <p className='instagram-title'>Enter Instagram handle</p>
          <form onSubmit={googleFinalHandleSubmit} className='phonenumber-form'>
            <input
              type='text'
              placeholder='e.g zaypurple'
              name='socialMediaHandle'
              value={formik.values.socialMediaHandle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.socialMediaHandle && formik.values.socialMediaHandle.length <= 0 ? "invalid" : "correct"}
              required
            />
            {formik.touched.socialMediaHandle && formik.values.socialMediaHandle.length <= 0 ? (
              <p className='error'>{formik.errors.socialMediaHandle}</p>
            ) : null}
            <button disabled={formik.values.socialMediaHandle.length <= 0 ? true : false} type='submit' className='intro1-btn btn'>
              {isLoading ? (
                <>
                  <span>Registering...</span>
                </>
              ) : (
                <>
                  <span>Continue</span>
                  <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                      d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </form>
        </div>
      ) : count == 4 && !emailSignup ? (
        <div className='w-[100%] h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      ) : null}
    </div>
  );
}
