import React from "react";
import OnBoardingComponent from "../../pages/onboarding/OnBoardingComponent";

export default function OnBoardingScreen() {
  return (
    <div>
      <OnBoardingComponent />
    </div>
  );
}
