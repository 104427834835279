import dayjs from "dayjs"
import React from "react"

interface transactionDetailsProp{
    description: string,
    date: string,
    amount: number
}


export default function TransactionDetails({description,date, amount}:transactionDetailsProp){

    function formatDate(date: string){
        const formattedDate = dayjs(date).format('MMM D, YYYY [at] h:mm a')
        return formattedDate
    }

    return (
        <div className={description == "" ? "flex justify-center items-center": ""}>
            {
            !(description == "")
            ?
            <>
            <div>
                <div>
                    <p className="trans-time">{`On ${formatDate(date)}`}</p>
                    <p>Token amount</p>
                    <p>Status</p>
                </div>
                <div>
                    <div>
                        <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M32 11.4438C32.0002 13.606 31.3606 15.7198 30.1618 17.519C28.9629 19.3181 27.2585 20.7222 25.2632 21.5542V21.5508C25.2632 17.7534 23.7549 14.1115 21.0702 11.4263C18.3854 8.74113 14.7442 7.23261 10.9474 7.23261H10.944C11.9188 4.89746 13.6721 2.97197 15.9057 1.78338C18.1393 0.594787 20.7156 0.216442 23.1966 0.712642C25.6775 1.20884 27.9102 2.54897 29.5151 4.50527C31.1199 6.46156 31.998 8.91332 32 11.4438ZM7.57895 1.33689C5.79223 1.33689 4.07868 2.04678 2.81528 3.31039C1.55188 4.57401 0.842105 6.28784 0.842105 8.07486V10.6016H4.21053V8.07486C4.21053 7.18135 4.56541 6.32443 5.19711 5.69263C5.82882 5.06082 6.68559 4.70587 7.57895 4.70587H10.1053V1.33689H7.57895ZM27.7895 22.393V24.9198C27.7895 25.8133 27.4346 26.6702 26.8029 27.302C26.1712 27.9338 25.3144 28.2888 24.4211 28.2888H21.8947V31.6578H24.4211C26.2078 31.6578 27.9213 30.9479 29.1847 29.6842C30.4481 28.4206 31.1579 26.7068 31.1579 24.9198V22.393H27.7895ZM10.9474 32.5C13.8508 32.5 16.6353 31.3464 18.6883 29.2931C20.7414 27.2397 21.8947 24.4547 21.8947 21.5508C21.8947 18.6469 20.7414 15.8619 18.6883 13.8085C16.6353 11.7552 13.8508 10.6016 10.9474 10.6016C8.04395 10.6016 5.25944 11.7552 3.20641 13.8085C1.15338 15.8619 0 18.6469 0 21.5508C0 24.4547 1.15338 27.2397 3.20641 29.2931C5.25944 31.3464 8.04395 32.5 10.9474 32.5ZM10.9474 17.3396L15.1579 21.5508L10.9474 25.762L6.73684 21.5508L10.9474 17.3396Z'
                              fill='#1C5D99'
                            />
                        </svg>
                        <span>{amount}</span>
                    </div>
                    <p className={`flex justify-center items-center ${description === "credit" ?"text-[#6eb424] bg-[#e6f7d5]" : "bg-[#f1f5fa] text-[#3770a5]"}`}>{description === "credit" ? "Top up" : "Withdrawal"}</p>
                </div>
            </div>
            <div className='report-trans'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                        d='M4.78125 23H3V3.18922L3.21984 2.96984C3.38156 2.80766 4.34906 2 7.5 2C9.24422 2 11.1952 2.68953 12.9164 3.2975C14.3025 3.78734 15.6117 4.25 16.5 4.25C18.5077 4.25 19.9088 3.58203 19.9219 3.575L21 3.05281V15.2136L20.5856 15.4208C20.5177 15.4531 18.8995 16.25 16.5 16.25C15.3684 16.25 13.9509 15.9153 12.4505 15.5605C10.7639 15.162 9.02016 14.75 7.5 14.75C5.77172 14.75 5.16844 15.0116 4.78125 15.177V23Z'
                        fill='#F41C1C'
                    />
                </svg>
                <p>Report transaction</p>
            </div>
            </>
            : <p className="text-base text-[#5f5f5f]">select a transaction to see details</p>
            }
        </div>
    )
}