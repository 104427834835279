import React from "react";
import { useState } from "react";

import "./UpcomingEventsStyles.css";

// import SidebarComponent from "../../SidebarComponent";
import PlannerHeader from "../Header/Header";
import Calendar from "react-calendar";

import { AppRoutes } from "../../../../utils/AppRoutes";
import { useNavigate } from "react-router-dom";
import SwitchRole from "../../SwitchRole";
import PlannerSidebarComponent from "../PlannerSidebar";
import { useMyContext } from "../../../../ContextApi";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const [value, onChange] = useState<Value>(new Date());

  const [activeTab, setActiveTab] = useState("");
  const { setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  const handleNavigation = (id: number) => {
    const eventId = id.toString();
    navigate(AppRoutes.PLANNER.UPCOMING_EVENT_DETAILS.replace(":eventId", eventId));
  };

  return (
    <div className='calendar-upcoming-event-page' onClick={handleClick}>
      <PlannerSidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />

      <div className='calendar-upcoming-event'>
        {/* <PlannerHeader title='Calendar' previousLink={() => navigate(AppRoutes.PLANNER.HOME)} /> */}
        <PlannerHeader title='Calendar' />
        <div className='calendar-upcoming-event-main'>
          <div className='planner-main-calendar'>
            <Calendar className='main-calender' onChange={onChange} value={value} />
          </div>
          <div className='calendar-upcoming-events'>
            <p className='upcoming-events-heading'>Today, October 18th 2023</p>
            <div className='upcoming-events-container'>
              <div className='upcoming-event' onClick={() => handleNavigation(1)}>
                <div className='upcoming-event-info-container'>
                  <p className='upcoming-event-time'>10:00 a.m</p>
                  <div className='vertical-line'></div>
                  <div className='upcoming-event-info'>
                    <p className='upcoming-event-location'>Ketu, Lagos</p>
                    <p className='upcoming-event-name'>Mummy’s 50th birthday</p>
                  </div>
                </div>
              </div>
              <div className='upcoming-event' onClick={() => handleNavigation(2)}>
                <div className='upcoming-event-info-container'>
                  <p className='upcoming-event-time'>11:00 a.m</p>
                  <div className='vertical-line'></div>
                  <div className='upcoming-event-info'>
                    <p className='upcoming-event-location'>Ikeja, Lagos</p>
                    <p className='upcoming-event-name'>Abimbola’s bridal shower</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
};

export default UpcomingEvents;
