import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "./utils/AppRoutes";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return <Navigate to={AppRoutes.LOGIN} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
