import React from "react";
import { useNavigate } from "react-router-dom";
import "./HeaderStyles.css";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { useMyContext } from "../../../../ContextApi";
import placeHolderImage from "../../../../assets/placeholderImage.svg"

interface PlannerHeaderProps {
  title: string;
  pageName?: string;
  spanText?: string;
  color?: string;
  hideArrow?: boolean;
}

const PlannerHeader: React.FC<PlannerHeaderProps> = ({ title, color, spanText, hideArrow, pageName }) => {
  const navigate = useNavigate();
  const backgroundColor = { backgroundColor: color ? color : "" };
  const { userInfo } = useMyContext();

  function handleNavigtion() {
    window.history.back();
  }

  return (
    <div className='planner-header' style={backgroundColor}>
      <div className='left'>
        {!hideArrow && (
          <div className='planner-previous-btn' onClick={handleNavigtion}>
            <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8.71416 0.308523C8.61661 0.210726 8.50072 0.133134 8.37314 0.0801925C8.24555 0.0272511 8.10878 0 7.97064 0C7.83251 0 7.69574 0.0272511 7.56815 0.0801925C7.44057 0.133134 7.32468 0.210726 7.22713 0.308523L0.24569 7.28997C0.167807 7.36769 0.106018 7.46001 0.0638593 7.56164C0.0217006 7.66328 0 7.77223 0 7.88226C0 7.99229 0.0217006 8.10123 0.0638593 8.20287C0.106018 8.3045 0.167807 8.39682 0.24569 8.47454L7.22713 15.456C7.6388 15.8677 8.3025 15.8677 8.71416 15.456C9.12582 15.0443 9.12582 14.3806 8.71416 13.969L2.63165 7.87805L8.72256 1.78714C9.12582 1.38388 9.12582 0.711783 8.71416 0.308523Z'
                fill='#262626'
              />
            </svg>
          </div>
        )}
        {
          pageName == "dashboard"
          &&
          <img
            className="block md:hidden h-[28px] w-[28px] rounded-full"
            src={userInfo.profilePicture ? userInfo.profilePicture : placeHolderImage}
            alt='profilepic'
          />
          }
        <p>
          {title} <span>{spanText}</span>
        </p>
      </div>
      <div className='right'>
        <svg
          className='notification-bell'
          onClick={() => navigate(AppRoutes.PLANNER.NOTIFICATIONS)}
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='26'
          viewBox='0 0 24 26'
          fill='none'
        >
          <path
            d='M7.66667 19.6667V21C7.66667 22.0609 8.08809 23.0783 8.83824 23.8284C9.58838 24.5786 10.6058 25 11.6667 25C12.7275 25 13.7449 24.5786 14.4951 23.8284C15.2452 23.0783 15.6667 22.0609 15.6667 21V19.6667M9 3.66667C9 2.95942 9.28095 2.28115 9.78105 1.78105C10.2811 1.28095 10.9594 1 11.6667 1C12.3739 1 13.0522 1.28095 13.5523 1.78105C14.0524 2.28115 14.3333 2.95942 14.3333 3.66667C15.8645 4.3907 17.1699 5.51777 18.1094 6.92707C19.0489 8.33636 19.5873 9.97476 19.6667 11.6667V15.6667C19.767 16.4956 20.0606 17.2894 20.5238 17.9842C20.9869 18.6789 21.6067 19.2552 22.3333 19.6667H1C1.72658 19.2552 2.34641 18.6789 2.80958 17.9842C3.27275 17.2894 3.56633 16.4956 3.66667 15.6667V11.6667C3.74608 9.97476 4.2844 8.33636 5.22393 6.92707C6.16346 5.51777 7.46879 4.3907 9 3.66667Z'
            stroke='#262626'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <img
          className='planner-profile-pic'
          src={userInfo.profilePicture ? userInfo.profilePicture : placeHolderImage}
          alt='profile-pic'
          onClick={() => navigate(AppRoutes.PLANNER.PROFILE)}
        />
      </div>
    </div>
  );
};

export default PlannerHeader;
