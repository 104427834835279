import React, { useState, useEffect, ChangeEvent } from "react";

import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import { timeDifference } from "../../utils/reuseableFunctions";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/AppRoutes";
import { HiOutlineBell } from "react-icons/hi";
import dayjs from "dayjs";
import { formatPrice } from "../../utils/reuseableFunctions";
import { useMyContext } from "../../ContextApi";
import MainLoader from "./MainLoader";
import placeHolderImage from "../../assets/placeholderImage.svg"

const baseUrl = process.env.REACT_APP_API_URL

export default function OrderRequestVComponent() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const jobCategory = sessionStorage.getItem("jobCategory");
  const [count, setCount] = useState(0);
  const { offerRequest, setOfferRequest, offerRequestRef } = useMyContext();
  const [fullContent, setFullContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (id: string) => {
    navigate(AppRoutes.V_ORDER_REQUEST.replace(":offerId", id));
  };

  const handleChangeDate = async (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const currentValue = e.target.value;
    setLoading(true);
    if (currentValue === "") {
      try {
        const response = await axios.get(`${baseUrl}/api/offers/search?category=${jobCategory}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data.status == 200) {
          setLoading(false);
          setOfferRequest(
            response.data.offers.filter(({ date }: { date: string }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(date).diff(currentTime, "day");
              return differenceInDays >= 0;
            })
          );
        } else {
          setLoading(false);
          setOfferRequest([]);
        }
      } catch (error) {
        setLoading(false);
        setOfferRequest([]);
      }
    } else {
      try {
        const response = await axios.get(
          `${baseUrl}/api/offers/search?date=${currentValue}&category=${jobCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.data.status === 200) {
          setLoading(false);
          setOfferRequest(
            response.data.offers.filter(({ date }: { date: string }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(date).diff(currentTime, "day");
              return differenceInDays >= 0;
            })
          );
        } else {
          setLoading(false);
          setOfferRequest([]);
        }
      } catch (error) {
        setLoading(false);
        setOfferRequest([]);
      }
    }
  };

  const handleChangeLocation = async (e: ChangeEvent<HTMLSelectElement>) => {
    const location = e.target.value;
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/offers/search?venue=${location}&category=${jobCategory}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setLoading(false);
        setOfferRequest(
          response.data.offers.filter(({ date }: { date: string }) => {
            const currentTime = dayjs();
            const differenceInDays = dayjs(date).diff(currentTime, "day");
            return differenceInDays >= 0;
          })
        );
      } else {
        setLoading(false);
        setOfferRequest([]);
      }
    } catch (error) {
      setLoading(false);
      setOfferRequest([]);
    }
  };

  const handleChangePrice = async (e: ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    const selectValue = e.target.value;
    const filteredSelectValue = selectValue.replace(/[^0-9|-]/g, "");
    const selectValueArray = filteredSelectValue.split("-");
    try {
      const response = await axios.get(
        `${baseUrl}/api/offers/search?category=${jobCategory}&minPrice=${parseInt(selectValueArray[0])}&maxPrice=${parseInt(selectValueArray[1])}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status === 200) {
        setLoading(false);
        setOfferRequest(
          response.data.offers.filter(({ date }: { date: string }) => {
            const currentTime = dayjs();
            const differenceInDays = dayjs(date).diff(currentTime, "day");
            return differenceInDays >= 0;
          })
        );
      } else {
        setLoading(false);
        setOfferRequest([]);
      }
    } catch (error) {
      setLoading(false);
      setOfferRequest([]);
    }
  };
  const getOffers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/offers/search?category=${jobCategory}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status == 200) {
        setLoading(false);
        setOfferRequest(
          response.data.offers.filter(({ date }: { date: string }) => {
            const currentTime = dayjs();
            const differenceInDays = dayjs(date).diff(currentTime, "day");
            return differenceInDays >= 0;
          })
        );
      } else {
        setLoading(false);
        setOfferRequest([]);
      }
    } catch (error) {
      setLoading(false);
      setOfferRequest([]);
    }
  };

  const fullContentChange = () => {
    setFullContent((prev) => {
      return !prev;
    });
  };
  useEffect(() => {
    if (!offerRequestRef.current) {
      getOffers();
      offerRequestRef.current = true;
    }
  }, []);

  return (
    <div className='orderrequest-page'>
      {offerRequest.length < 1 && !offerRequestRef.current ? (
        <div className='flex-1 h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      ) : (
        <div className='orderrequest-main'>
          <HeaderComponent pageName='Order Request' count={count} setCount={setCount} />
          <>
              <div className='selection-container big-screen'>
                <div>
                  <label htmlFor='Date'>Date</label>

                  <div className='wrap'>
                    <select name='date' onChange={handleChangeDate} id='Date'>
                      <option value=''>All</option>
                      <option value='today'>Today</option>
                      <option value='yesterday'>Yesterday</option>
                      <option value='thisWeek'>This week</option>
                      <option value='thisMonth'>This month</option>
                      <option value='thisYear'>This year</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor='Location'>Location</label>

                  <div className='wrap'>
                    <select name='location' onChange={handleChangeLocation} id='Date' className='bg-none'>
                      <option value='Lagos'>Lagos</option>
                      <option value='Abeokuta'>Abeokuta</option>
                      <option value='Ibadan'>Ibadan</option>
                      <option value='Port Harcourt'>Port Harcourt</option>
                      <option value='Kano'>Kano</option>
                      <option value='Abuja'>Abuja</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor='Price'>Price range</label>

                  <div className='wrap'>
                    <select name='price' onChange={handleChangePrice} id='Price'>
                      <option value=''>All</option>
                      <option
                        value={`${formatPrice("10000")} - ${formatPrice("100000")}`}
                      >{`${formatPrice("10000")} - ${formatPrice("100000")}`}</option>
                      <option
                        value={`${formatPrice("100000")} - ${formatPrice("1000000")}`}
                      >{`${formatPrice("100000")} - ${formatPrice("1000000")}`}</option>
                      <option
                        value={`${formatPrice("1000000")} - ${formatPrice("10000000")}`}
                      >{`${formatPrice("1000000")} - ${formatPrice("10000000")}`}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='selection-container small-screen'>
                <div>
                  <label htmlFor='Date'>Date</label>

                  <div className='wrap'>
                    <select name='date' onChange={handleChangeDate} id='Date'>
                      <option value=''>All</option>
                      <option value='today'>Today</option>
                      <option value='yesterday'>Yesterday</option>
                      <option value='thisWeek'>This week</option>
                      <option value='thisMonth'>This month</option>
                      <option value='thisYear'>This year</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor='Price'>Price</label>

                  <div className='wrap'>
                    <select name='price' onChange={handleChangePrice} id='Price'>
                      <option value=''>All</option>
                      <option
                        value={`${formatPrice("10000")} - ${formatPrice("100000")}`}
                      >{`${formatPrice("10000")} - ${formatPrice("100000")}`}</option>
                      <option
                        value={`${formatPrice("100000")} - ${formatPrice("1000000")}`}
                      >{`${formatPrice("100000")} - ${formatPrice("1000000")}`}</option>
                      <option
                        value={`${formatPrice("1000000")} - ${formatPrice("10000000")}`}
                      >{`${formatPrice("1000000")} - ${formatPrice("10000000")}`}</option>
                    </select>
                  </div>
                </div>
              </div>
            {!fullContent && (
              <div className={!loading && offerRequest.length > 0 ? "order-sometimes" : "order-sometimes norequest"}>
                {loading ? (
                  <MainLoader />
                ) : !loading && offerRequest.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Planner</th>
                        <th>Date</th>
                        <th>Location</th>
                        <th>Event Title</th>
                      </tr>
                    </thead>
                    <tbody>
                    {offerRequest.slice(0, 3).map(({ _id, plannerName, venue, date, eventTitle,plannerProfilepic }) => {
                      return (
                        <tr key={_id} onClick={() => handleClick(_id)}>
                          <td>
                            <img 
                              className="w-[20px] h-[20px]" 
                              src={plannerProfilepic ? plannerProfilepic : placeHolderImage} alt='planner picture' />
                            <p>{!plannerName ? "no name" : plannerName}</p>
                          </td>
                          <td>{timeDifference(date)}</td>
                          <td>{venue}</td>
                          <td>
                            <p>{eventTitle}</p>
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='none'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M20.502 15.0243C20.752 15.2744 20.8924 15.6134 20.8924 15.967C20.8924 16.3205 20.752 16.6596 20.502 16.9097L12.9594 24.4523C12.8364 24.5797 12.6893 24.6812 12.5266 24.7511C12.3639 24.821 12.189 24.8578 12.0119 24.8593C11.8349 24.8609 11.6593 24.8271 11.4954 24.7601C11.3316 24.693 11.1827 24.594 11.0575 24.4689C10.9323 24.3437 10.8333 24.1948 10.7663 24.0309C10.6992 23.8671 10.6655 23.6915 10.667 23.5145C10.6686 23.3374 10.7054 23.1625 10.7752 22.9998C10.8451 22.8371 10.9467 22.69 11.074 22.567L17.674 15.967L11.074 9.36699C10.8312 9.11552 10.6968 8.77872 10.6998 8.42912C10.7028 8.07953 10.8431 7.74511 11.0903 7.4979C11.3375 7.25069 11.6719 7.11046 12.0215 7.10742C12.3711 7.10439 12.7079 7.23878 12.9594 7.48166L20.502 15.0243Z'
                                fill='#5F5F5F'
                              />
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p>No request available</p>
                    <div>
                      <HiOutlineBell size={20} />
                      <p>We will notify you once there is a request</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            { fullContent && (
              <div className={!loading && offerRequest.length > 0 ? "order-sometimes" : "order-sometimes norequest"}>
                {loading ? (
                  <MainLoader />
                ) : !loading && offerRequest.length > 0 ? (
                  <table>
                    <thead>
                    <tr>
                      <th>Planner</th>
                      <th>Date</th>
                      <th>Location</th>
                      <th>Event Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offerRequest.map(({ _id, plannerName, venue, date, eventTitle, plannerProfilepic }) => {
                      return (
                        <tr key={_id} onClick={() => handleClick(_id)}>
                          <td>
                            <img 
                              className="w-[20px] h-[20px]" 
                              src={plannerProfilepic ? plannerProfilepic : placeHolderImage} 
                              alt='planner picture' 
                            />
                            <p>{!plannerName ? "no name" : plannerName}</p>
                          </td>
                          <td>{timeDifference(date)}</td>
                          <td>{venue}</td>
                          <td className=''>
                            <p>{eventTitle}</p>
                            {/* <p>{formatPrice(offeredAmount.toString())}</p> */}
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='none'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M20.502 15.0243C20.752 15.2744 20.8924 15.6134 20.8924 15.967C20.8924 16.3205 20.752 16.6596 20.502 16.9097L12.9594 24.4523C12.8364 24.5797 12.6893 24.6812 12.5266 24.7511C12.3639 24.821 12.189 24.8578 12.0119 24.8593C11.8349 24.8609 11.6593 24.8271 11.4954 24.7601C11.3316 24.693 11.1827 24.594 11.0575 24.4689C10.9323 24.3437 10.8333 24.1948 10.7663 24.0309C10.6992 23.8671 10.6655 23.6915 10.667 23.5145C10.6686 23.3374 10.7054 23.1625 10.7752 22.9998C10.8451 22.8371 10.9467 22.69 11.074 22.567L17.674 15.967L11.074 9.36699C10.8312 9.11552 10.6968 8.77872 10.6998 8.42912C10.7028 8.07953 10.8431 7.74511 11.0903 7.4979C11.3375 7.25069 11.6719 7.11046 12.0215 7.10742C12.3711 7.10439 12.7079 7.23878 12.9594 7.48166L20.502 15.0243Z'
                                fill='#5F5F5F'
                              />
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p>No request available</p>
                    <div>
                      <HiOutlineBell size={20} />
                      <p>We will notify you once there is a request</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {!fullContent && (
              <div className={!loading && offerRequest.length > 0 ? "order-mobile" : "order-mobile norequest"}>
                {loading ? (
                  <MainLoader />
                ) : !loading && offerRequest.length > 0 ? (
                  <table>
                    <thead>
                    <tr>
                      <th>Planner</th>
                      <th>Date</th>
                      <th>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offerRequest.slice(0, 3).map(({ _id, plannerName, date, eventTitle }) => {
                      return (
                        <tr key={_id} onClick={() => handleClick(_id)}>
                          <td>{!plannerName ? "no name" : plannerName.split(" ")[0]}</td>
                          <td>
                            <svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <circle cx='3' cy='3' r='3' fill='#1C5D99' />
                            </svg>
                            <span>{timeDifference(date)}</span>
                          </td>
                          <td>
                            <span>{eventTitle}</span>
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='none'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M20.502 15.0243C20.752 15.2744 20.8924 15.6134 20.8924 15.967C20.8924 16.3205 20.752 16.6596 20.502 16.9097L12.9594 24.4523C12.8364 24.5797 12.6893 24.6812 12.5266 24.7511C12.3639 24.821 12.189 24.8578 12.0119 24.8593C11.8349 24.8609 11.6593 24.8271 11.4954 24.7601C11.3316 24.693 11.1827 24.594 11.0575 24.4689C10.9323 24.3437 10.8333 24.1948 10.7663 24.0309C10.6992 23.8671 10.6655 23.6915 10.667 23.5145C10.6686 23.3374 10.7054 23.1625 10.7752 22.9998C10.8451 22.8371 10.9467 22.69 11.074 22.567L17.674 15.967L11.074 9.36699C10.8312 9.11552 10.6968 8.77872 10.6998 8.42912C10.7028 8.07953 10.8431 7.74511 11.0903 7.4979C11.3375 7.25069 11.6719 7.11046 12.0215 7.10742C12.3711 7.10439 12.7079 7.23878 12.9594 7.48166L20.502 15.0243Z'
                                fill='#5F5F5F'
                              />
                            </svg>
                            {/* {formatPrice(offeredAmount.toString())} */}
                            </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p>No request available</p>
                    <div>
                      <HiOutlineBell size={20} />
                      <p>We will notify you once there is a request</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {fullContent && (
              <div className={!loading && offerRequest.length > 0 ? "order-mobile" : "order-mobile norequest"}>
                {loading ? (
                  <MainLoader />
                ) : !loading && offerRequest.length > 0 ? (
                  <table>
                    <thead>
                    <tr>
                      <th>Planner</th>
                      <th>Date</th>
                      <th>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offerRequest.map(({ _id, plannerName, eventTitle, date }) => {
                      return (
                        <tr key={_id} onClick={() => handleClick(_id)}>
                          <td>{!plannerName ? "no name" : plannerName.split(" ")[0]}</td>
                          <td>
                            <svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <circle cx='3' cy='3' r='3' fill='#1C5D99' />
                            </svg>
                            <span>{timeDifference(date)}</span>
                          </td>
                          <td>{eventTitle}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p>No request available</p>
                    <div>
                      <HiOutlineBell size={20} />
                      <p>We will notify you once there is a request</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
          <div className='orderrequest-btn'>
            {offerRequest.length > 3 && !loading ? (
              <button onClick={fullContentChange}>{fullContent ? "See less" : "See More"}</button>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
