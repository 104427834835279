import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { notificationTimeDifference } from "../../utils/reuseableFunctions";
import PlannerHeader from "./Planner/Header/Header";
import { formatPrice } from "../../utils/reuseableFunctions";

const baseUrl = process.env.REACT_APP_API_URL

const ENDPOINT = `${baseUrl}/api/proposals/vendor`;

interface proposalObj {
  _id: string;
  updatedAt: string;
  vendor: string;
  proposedAmount: string | number;
  status: string;
  declineReason: string;
}

export default function NotificationVComponent() {
  const token = sessionStorage.getItem("token");
  const [proposals, setProposals] = useState<proposalObj[]>([]);

  async function getProposals() {
    try {
      const response = await axios.get(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposals(
          response.data.proposals.filter(({ status }: { status: string }) => {
            return status == "accepted" || status == "declined";
          })
        );
      } else {
        setProposals([]);
      }
    } catch (error) {
      setProposals([]);
    }
  }

  useEffect(() => {
    getProposals();
  }, []);

  return (
    <div className='notification-page'>
      {proposals.length > 0 ? (
        <div className='notifications-main'>
          <PlannerHeader title='Notification' />
          <div className='notifications-container'>
            {proposals.filter(({ updatedAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
              return differenceInDays == 0;
            }).length > 0 && <p className='period mb-2'>Today</p>}
            {proposals
              .filter(({ updatedAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
                return differenceInDays == 0;
              })
              .map(({ _id, updatedAt, vendor, proposedAmount, status, declineReason }) => (
                <div className='mb-3 md:mb-4  notification-offer-container hover:bg-white p-1 rounded-xl' key={_id}>
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(updatedAt)}</p>
                  </div>
                  {status == "declined" ? (
                    <p className='notification-message'>{`${vendor} declined your offer of ${formatPrice(proposedAmount.toString())} for the event because ${declineReason}`}</p>
                  ) : (
                    <p className='notification-message'>{`${vendor} accepted your offer of ${formatPrice(proposedAmount.toString())} for the event`}</p>
                  )}
                </div>
              ))}
            {proposals.filter(({ updatedAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
              return differenceInDays <= -1 && differenceInDays >= -7;
            }).length > 0 && <p className='period mb-2'>This week</p>}
            {proposals
              .filter(({ updatedAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
                return differenceInDays <= -1 && differenceInDays >= -7;
              })
              .map(({ _id, updatedAt, vendor, proposedAmount, status, declineReason }) => (
                <div className='mb-3 md:mb-4  notification-offer-container hover:bg-white p-1 rounded-xl' key={_id}>
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(updatedAt)}</p>
                  </div>
                  {status == "declined" ? (
                    <p className='notification-message'>{`${vendor} declined your offer of ${formatPrice(proposedAmount.toString())} for the event because ${declineReason}`}</p>
                  ) : (
                    <p className='notification-message'>{`${vendor} accepted your offer of ${formatPrice(proposedAmount.toString())} for the event`}</p>
                  )}
                </div>
              ))}
            {proposals.filter(({ updatedAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
              return differenceInDays <= -8 && differenceInDays >= -30;
            }).length > 0 && <p className='period mb-2'>This month</p>}
            {proposals
              .filter(({ updatedAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(updatedAt).diff(currentTime, "day");
                return differenceInDays <= -8 && differenceInDays >= -30;
              })
              .map(({ _id, updatedAt, vendor, proposedAmount, status, declineReason }) => (
                <div className='mb-3 md:mb-4 notification-offer-container hover:bg-white p-1 rounded-xl' key={_id}>
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(updatedAt)}</p>
                  </div>
                  {status == "declined" ? (
                    <p className='notification-message'>{`${vendor} declined your offer of ${formatPrice(proposedAmount.toString())} for the event because ${declineReason}`}</p>
                  ) : (
                    <p className='notification-message'>{`${vendor} accepted your offer of ${formatPrice(proposedAmount.toString())} for the event`}</p>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className='flex justify-center flex-col items-center flex-1 h-full'>
          <p className='text-2xl text-[#1C5D99]'>no notifications yet</p>
          <p className='text-xl'>no response yet</p>
        </div>
      )}
    </div>
  );
}
