import React from "react";
import "./SuccessModalStyles.css";
import successSvg from "../../../../../assets/success-icon.svg";
import { AppRoutes } from "../../../../../utils/AppRoutes";

interface SuccessModalProps {
  message: string;
  description?: string;
  showBtn?: boolean;
  closeModal: () => void;
  eventId?: string;
}

const SuccessModal = ({ message, description, showBtn, closeModal, eventId }: SuccessModalProps) => {
  return (
    <>
      <div className='planner-modal success-modal'>
        <div className='success-image-container'>
          <img src={successSvg} alt='success-icon' />
        </div>
        <h1 className='success-msg'>{message}</h1>
        <p className='description'>{description}</p>
        {eventId && (
          <p>
            Click{" "}
            <a className='text-[#1c5d99] underline hover:no-underline' href={AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId)}>
              continue
            </a>{" "}
            to search for vendors
          </p>
        )}
        {showBtn && (
          <button
            className='modal-btn'
            type='button'
            onClick={closeModal}
            style={{ display: "inline-flex", gap: "0.5rem", justifyContent: "center", alignItems: "center" }}
          >
            <span>Continue</span>
            <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
              <path
                d='M11.5577 3.05737C11.8077 2.80741 12.1468 2.66699 12.5003 2.66699C12.8539 2.66699 13.193 2.80741 13.443 3.05737L19.443 9.05737C19.693 9.30741 19.8334 9.64649 19.8334 10C19.8334 10.3536 19.693 10.6927 19.443 10.9427L13.443 16.9427C13.1915 17.1856 12.8547 17.32 12.5051 17.3169C12.1555 17.3139 11.8211 17.1737 11.5739 16.9265C11.3267 16.6793 11.1865 16.3448 11.1834 15.9952C11.1804 15.6456 11.3148 15.3088 11.5577 15.0574L15.167 11.3334H2.50033C2.1467 11.3334 1.80757 11.1929 1.55752 10.9429C1.30747 10.6928 1.16699 10.3537 1.16699 10C1.16699 9.64642 1.30747 9.30728 1.55752 9.05723C1.80757 8.80718 2.1467 8.66671 2.50033 8.66671H15.167L11.5577 4.94271C11.3077 4.69267 11.1673 4.35359 11.1673 4.00004C11.1673 3.64649 11.3077 3.30741 11.5577 3.05737Z'
                fill='#1c5d99'
              />
            </svg>
          </button>
        )}

        <div className='close-modal' onClick={closeModal}>
          <img src='/close-icon.svg' alt='close-icon' />
        </div>
      </div>

      <div className='planner-modal-overlay' onClick={closeModal}></div>
    </>
  );
};

export default SuccessModal;
