import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import HomeScreen from "./components/screens/home/HomeScreen";
import { AppRoutes } from "./utils/AppRoutes";
import OnBoardingScreen from "./components/screens/onboarding/OnBoardingScreen";
import LoginScreen from "./components/screens/login/LoginScreen";
import MyEvents from "./components/common/Planner/Events/MyEvents";
import EventDetails from "./components/common/Planner/Events/EventDetails";
import VendorRequest from "./components/common/Planner/Request/VendorRequest";
import PlannerDashboard from "./components/common/Planner/Dashboard/PlannerDashboard";
import EventVendors from "./components/common/Planner/EventVendors/EventVendors";
import Notifications from "./components/common/Planner/Notifications/Notifications";
import ProtectedRoute from "./ProtectedRoute";
import DashboardPage from "./components/pages/vendor/DashboardPage";
import OrderRequestPage from "./components/pages/vendor/OrderRequestPage";
import OfferPage from "./components/pages/vendor/OfferPage";
import HistoryPage from "./components/pages/vendor/HistoryPage";
import WalletPage from "./components/pages/vendor/WalletPage";
import TransactionDetailPage from "./components/pages/vendor/TransactionDetailPage";
import ProfilePage from "./components/pages/vendor/ProfilePage";
import PasswordPage from "./components/pages/vendor/PasswordPage";
import SupportPage from "./components/pages/vendor/SupportPage";
import NotificationPage from "./components/pages/vendor/NotificationPage";
import ReportEventPage from "./components/pages/vendor/ReportEventPage";

import PlannerHistory from "./components/common/Planner/History/PlannerHistory";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UpcomingEvents from "./components/common/Planner/UpcomingEvents/UpcomingEvents";
import UpcomingEventDetails from "./components/common/Planner/UpcomingEvents/UpcomingEventDetails";
import VendorDetails from "./components/common/Planner/VendorDetails/VendorDetails";
import PlannerProfile from "./components/common/Planner/Profile/PlannerProfile";
import ChangePassword from "./components/common/Planner/Password/ChangePassword";
import PlannerSupport from "./components/common/Planner/Support/PlannerSupport";
import ReportEvent from "./components/common/Planner/ReportEvent/ReportEvent";
import CalenderPage from "./components/pages/vendor/CalenderPage";
import RequestComponent from "./components/common/RequestComponentPage";
import EditVendorRequest from "./components/common/Planner/Request/EditVendorRequest";
import OfferDetailsPage from "./components/pages/vendor/OfferDetailsPage";
import ResetComponent from "./components/pages/reset/ResetComponent";
import SignupComponent from "./components/pages/signup/SignupComponent";
import ProtectedRoute2 from "./ProtectedRoute2";
import ProtectedRoute3 from "./ProtectedRouted3";

const getMainLayout = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path={AppRoutes.HOME} element={<HomeScreen />} />
        <Route path={AppRoutes.ANY} element={<OnBoardingScreen />} />
        <Route path={AppRoutes.SIGNUP} element={<SignupComponent />} />
        <Route path={AppRoutes.ONBOARDING} element={<OnBoardingScreen />} />
        <Route path={AppRoutes.LOGIN} element={<LoginScreen />} />
        <Route
          path={AppRoutes.PLANNER.HOME}
          element={
            <ProtectedRoute3>
              <PlannerDashboard />
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.NOTIFICATIONS}
          element={
            <ProtectedRoute3>
              <Notifications />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.EVENTS}
          element={
            <ProtectedRoute3>
              <MyEvents />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.EVENT_DETAILS}
          element={
            <ProtectedRoute3>
              {" "}
              <EventDetails />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.VENDOR}
          element={
            <ProtectedRoute3>
              <EventVendors />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.VENDOR_REQUEST}
          element={
            <ProtectedRoute3>
              <VendorRequest />
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.EDIT_VENDOR_REQUEST}
          element={
            <ProtectedRoute3>
              <EditVendorRequest />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.HISTORY}
          element={
            <ProtectedRoute3>
              <PlannerHistory />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.UPCOMING_EVENTS}
          element={
            <ProtectedRoute3>
              <UpcomingEvents />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.UPCOMING_EVENT_DETAILS}
          element={
            <ProtectedRoute3>
              <UpcomingEventDetails />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.VENDOR_DETAILS}
          element={
            <ProtectedRoute>
              <VendorDetails />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.PLANNER.PROFILE}
          element={
            <ProtectedRoute3>
              <PlannerProfile />
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.CHANGE_PASSWORD}
          element={
            <ProtectedRoute3>
              <ChangePassword />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.SUPPORT}
          element={
            <ProtectedRoute3>
              <PlannerSupport />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.PLANNER.REPORT_EVENT}
          element={
            <ProtectedRoute3>
              <ReportEvent />{" "}
            </ProtectedRoute3>
          }
        />
        <Route
          path={AppRoutes.V_DASH}
          element={
            <ProtectedRoute2>
              <DashboardPage />
            </ProtectedRoute2>
            // : <Navigate to={AppRoutes.PLANNER.HOME} />
          }
        />
        <Route
          path={AppRoutes.V_ORDER}
          element={
            <ProtectedRoute2>
              <OrderRequestPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_OFFER}
          element={
            <ProtectedRoute2>
              <OfferPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_OFFER_DETAILS}
          element={
            <ProtectedRoute2>
              <OfferDetailsPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_HISTORY}
          element={
            <ProtectedRoute2>
              <HistoryPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_WALLET}
          element={
            <ProtectedRoute2>
              <WalletPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_TRANSACTION_HISTORY}
          element={
            <ProtectedRoute2>
              <TransactionDetailPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_PROFILE}
          element={
            <ProtectedRoute2>
              <ProfilePage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_PASSWORD}
          element={
            <ProtectedRoute>
              <PasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.V_SUPPORT}
          element={
            <ProtectedRoute2>
              <SupportPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_NOTIFICATION}
          element={
            <ProtectedRoute2>
              <NotificationPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_REPORT_EVENT}
          element={
            <ProtectedRoute2>
              <ReportEventPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={`${AppRoutes.V_CALENDAR}`}
          element={
            <ProtectedRoute2>
              <CalenderPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={`${AppRoutes.V_CALENDAR}/:datetime`}
          element={
            <ProtectedRoute2>
              <CalenderPage />
            </ProtectedRoute2>
          }
        />
        <Route
          path={AppRoutes.V_ORDER_REQUEST}
          element={
            <ProtectedRoute2>
              <RequestComponent />
            </ProtectedRoute2>
          }
        />
        <Route path={AppRoutes.RESET_PASSWORD} element={<ResetComponent />}></Route>
      </Routes>
    </MainLayout>
  );
};

function App() {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>{getMainLayout()}</LocalizationProvider>;
}

export default App;
