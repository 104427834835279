import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import partyPalDark from "../../../assets/Partypal-dark.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/AppRoutes";
import wrong from "../../../assets/wrong.svg";
import correct from "../../../assets/correct.svg";
import CountDownComponent from "../../common/CountDownComponent/CountDownComponent";
import SuccessModal2 from "../../common/Planner/Modals/SuccessModal/SuccessModal2";
import { Spinner } from "../../common/loader";

const baseUrl = process.env.REACT_APP_API_URL;

export default function ResetComponent() {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [emailVerifyingLoading, setEmailVerifyingLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [countdownKey, setCountdownKey] = useState(0);
  const [isCountDownOver, setIsCountDownOver] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  function handleClick1() {
    if (count == 0) {
      navigate(AppRoutes.LOGIN);
    } else {
      setCount((prev) => {
        return prev - 1;
      });
    }
  }

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string()
      .matches(/[^a-zA-Z0-9]/, "Must contain at least one special case")
      .matches(/.{8,}/, "Must contain at least 8 letters")
      .matches(/(?=.*[A-Z])/, "Must contain at least one upper case")
      .matches(/(?=.*[a-z])/, "Must contain at least lower case"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password", undefined), undefined] as const, "Passwords must match")
      .required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleResetFormSubmit(values);
    }
  });


  const handleContinueClick = async () => {
    if (formik.errors.email) return;
    try {
      setEmailVerifyingLoading(true);
      setIsCountDownOver(false);
      const response = await axios.post(`${baseUrl}/api/auth/sendotp`, {
        email: formik.values.email,
        type: "forgot"
      });
      if (response.data.status === 200) {
        toast.success(response.data.message)
        setEmailVerifyingLoading(false);
        if (count == 0) {
          setCount(1);
        }
        if (isCountDownOver) {
          restartCountdown();
          setIsCountDownOver(false);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setEmailVerifyingLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  const handleContinueClick2 = async () => {
    if (!formik.values.email || otp.length < 6) return;
    try {
      setOtpLoading(true);
      const response = await axios.post(`${baseUrl}/api/auth/verifyotp`, {
        email: formik.values.email,
        otp: otp,
        type: "forgot"
      });
      if (response.status === 200) {
        setOtpLoading(false);
        setCount(2);
      }
      // console.log(response.data)
    } catch (error: any) {
      setOtpLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  const handleResetFormSubmit = async (values: { email: string; password: string; confirmPassword: string }) => {
    try {
      setResetPasswordLoading(true);
      const response = await axios.post(`${baseUrl}/api/auth/resetpassword`, {
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword
      });
      if (response.data.status === 200) {
        setResetPasswordLoading(false);
        setIsSuccessModalVisible(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setResetPasswordLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const restartCountdown = () => {
    setCountdownKey((prevKey) => prevKey + 1);
  };

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      if (count == 0) {
        handleContinueClick();
      } else if (count == 1) {
        handleContinueClick2();
      } else {
        formik.handleSubmit();
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [count, formik.values, otp]);



  return (
    <div className='loginpage'>
      <Toaster />
      {isSuccessModalVisible && <SuccessModal2 message='password successfully changed' showBtn={true} closeModal={closeSuccessModal} />}
      <svg
        onClick={handleClick1}
        className='loginpage-svg'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M16.62 2.98979C16.5039 2.87338 16.366 2.78103 16.2141 2.71801C16.0622 2.655 15.8994 2.62256 15.735 2.62256C15.5706 2.62256 15.4078 2.655 15.2559 2.71801C15.1041 2.78103 14.9661 2.87338 14.85 2.98979L6.54 11.2998C6.4473 11.3923 6.37375 11.5022 6.32357 11.6232C6.27339 11.7441 6.24756 11.8738 6.24756 12.0048C6.24756 12.1358 6.27339 12.2654 6.32357 12.3864C6.37375 12.5074 6.4473 12.6173 6.54 12.7098L14.85 21.0198C15.34 21.5098 16.13 21.5098 16.62 21.0198C17.11 20.5298 17.11 19.7398 16.62 19.2498L9.38 11.9998L16.63 4.74979C17.11 4.26979 17.11 3.46979 16.62 2.98979Z'
          fill='#262626'
        />
      </svg>
      <img src={partyPalDark} alt='logo' />
      <div className='loginpage-main'>
        {count == 0 ? (
          <div className='resetpage'>
            <p className='resetpage-title'>Reset Password</p>
            <p className='title'>Enter your registered email to receive an OTP</p>
            <input
              type='text'
              id='email'
              name='email'
              placeholder='E-mail address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={formik.touched.email && formik.errors.email ? "invalid" : "correct"}
            />

            <button
              type='submit'
              onClick={handleContinueClick}
              className={!formik.errors.email ? "intro1-btn btn" : "intro1-btn btn disabled"}
            >
              {/* <span>{!emailVerifyingLoading ? "verifying..." : "Continue"}</span> */}
              {emailVerifyingLoading ? (
                <>
                  <span className='text-xl font-normal'>Verifying...</span>
                  <Spinner />
                </>
              ) : (
                <>
                  <span>Continue</span>
                  <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                      d='M11.5577 3.05737C11.8077 2.80741 12.1468 2.66699 12.5003 2.66699C12.8539 2.66699 13.193 2.80741 13.443 3.05737L19.443 9.05737C19.693 9.30741 19.8334 9.64649 19.8334 10C19.8334 10.3536 19.693 10.6927 19.443 10.9427L13.443 16.9427C13.1915 17.1856 12.8547 17.32 12.5051 17.3169C12.1555 17.3139 11.8211 17.1737 11.5739 16.9265C11.3267 16.6793 11.1865 16.3448 11.1834 15.9952C11.1804 15.6456 11.3148 15.3088 11.5577 15.0574L15.167 11.3334H2.50033C2.1467 11.3334 1.80757 11.1929 1.55752 10.9429C1.30747 10.6928 1.16699 10.3537 1.16699 10C1.16699 9.64642 1.30747 9.30728 1.55752 9.05723C1.80757 8.80718 2.1467 8.66671 2.50033 8.66671H15.167L11.5577 4.94271C11.3077 4.69267 11.1673 4.35359 11.1673 4.00004C11.1673 3.64649 11.3077 3.30741 11.5577 3.05737Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        ) : count == 1 ? (
          <div className='login-otp'>
            <p className='login-otp-title'>Enter verification code</p>
            <p className='login-otp-desc'>An otp has been sent to your mail</p>
            <OtpInput
              value={otp}
              containerStyle='otp-container'
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputType='tel'
            />
            <p className='login-otp-link'>
              <a
                className={isCountDownOver ? "send-otp" : "dont-send-otp"}
                onClick={() => {
                  if (isCountDownOver) {
                    handleContinueClick();
                  }
                }}
                href='javascript:void(0)'
              >
                Resend otp
              </a>{" "}
              in{" "}
              <CountDownComponent isCountDownOver={isCountDownOver} setIsCountDownOver={setIsCountDownOver} countdownKey={countdownKey} />
            </p>

            <button onClick={handleContinueClick2} className={otp.length != 6 ? "intro1-btn disabled" : "intro1-btn"}>
              {otpLoading ? (
                <>
                  <span className='text-xl font-normal'>Verifying...</span>
                  <Spinner />
                </>
              ) : (
                <>
                  <span>Continue</span>
                  <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                      d='M11.5577 3.05737C11.8077 2.80741 12.1468 2.66699 12.5003 2.66699C12.8539 2.66699 13.193 2.80741 13.443 3.05737L19.443 9.05737C19.693 9.30741 19.8334 9.64649 19.8334 10C19.8334 10.3536 19.693 10.6927 19.443 10.9427L13.443 16.9427C13.1915 17.1856 12.8547 17.32 12.5051 17.3169C12.1555 17.3139 11.8211 17.1737 11.5739 16.9265C11.3267 16.6793 11.1865 16.3448 11.1834 15.9952C11.1804 15.6456 11.3148 15.3088 11.5577 15.0574L15.167 11.3334H2.50033C2.1467 11.3334 1.80757 11.1929 1.55752 10.9429C1.30747 10.6928 1.16699 10.3537 1.16699 10C1.16699 9.64642 1.30747 9.30728 1.55752 9.05723C1.80757 8.80718 2.1467 8.66671 2.50033 8.66671H15.167L11.5577 4.94271C11.3077 4.69267 11.1673 4.35359 11.1673 4.00004C11.1673 3.64649 11.3077 3.30741 11.5577 3.05737Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        ) : (
          <div className='login-ch-password'>
            <p className='login-ch-password-title'>Create new password</p>
            <form onSubmit={formik.handleSubmit}>
              <input
                type='password'
                placeholder='New password'
                name='password'
                id='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={formik.touched.password && formik.errors.password ? "invalid" : "correct"}
              />
              <br />
              <input
                type='password'
                placeholder='Confirm password'
                name='confirmPassword'
                id='confirmPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "invalid" : "correct"}
              />
              {formik.errors.confirmPassword == "Passwords must match" && <p className='error'>{formik.errors.confirmPassword}</p>}
              {formik.errors.password && (
                <div className='password-criteria'>
                  <div>
                    {!/[^a-zA-Z0-9]/.test(formik.values.password) ? (
                      <img src={wrong} alt='wrong icon' />
                    ) : (
                      <img src={correct} alt='correct icon' />
                    )}
                    <p>Must contain at least one special case</p>
                  </div>
                  <div>
                    {!/.{8,}/.test(formik.values.password) ? (
                      <img src={wrong} alt='wrong icon' />
                    ) : (
                      <img src={correct} alt='correct icon' />
                    )}
                    <p>Must contain at least 8 letters</p>
                  </div>
                  <div>
                    {!/(?=.*[A-Z])/.test(formik.values.password) ? (
                      <img src={wrong} alt='wrong icon' />
                    ) : (
                      <img src={correct} alt='correct icon' />
                    )}
                    <p>Must contain at least one upper case</p>
                  </div>
                  <div>
                    {!/(?=.*[a-z])/.test(formik.values.password) ? (
                      <img src={wrong} alt='wrong icon' />
                    ) : (
                      <img src={correct} alt='correct icon' />
                    )}
                    <p>Must contain at least lower case</p>
                  </div>
                </div>
              )}
              <button type='submit' className='intro1-btn btn'>
                {resetPasswordLoading ? (
                  <>
                    <span>Resetting...</span>
                    <Spinner />
                  </>
                ) : (
                  <>
                    <span>Continue</span>
                    <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                      <path
                        d='M11.5577 3.05737C11.8077 2.80741 12.1468 2.66699 12.5003 2.66699C12.8539 2.66699 13.193 2.80741 13.443 3.05737L19.443 9.05737C19.693 9.30741 19.8334 9.64649 19.8334 10C19.8334 10.3536 19.693 10.6927 19.443 10.9427L13.443 16.9427C13.1915 17.1856 12.8547 17.32 12.5051 17.3169C12.1555 17.3139 11.8211 17.1737 11.5739 16.9265C11.3267 16.6793 11.1865 16.3448 11.1834 15.9952C11.1804 15.6456 11.3148 15.3088 11.5577 15.0574L15.167 11.3334H2.50033C2.1467 11.3334 1.80757 11.1929 1.55752 10.9429C1.30747 10.6928 1.16699 10.3537 1.16699 10C1.16699 9.64642 1.30747 9.30728 1.55752 9.05723C1.80757 8.80718 2.1467 8.66671 2.50033 8.66671H15.167L11.5577 4.94271C11.3077 4.69267 11.1673 4.35359 11.1673 4.00004C11.1673 3.64649 11.3077 3.30741 11.5577 3.05737Z'
                        fill='white'
                      />
                    </svg>
                  </>
                )}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
