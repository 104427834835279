import React, { useEffect, useState } from "react";
import image from "../../assets/ellipse-193.svg";
import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import dayjs from "dayjs";
import { extractCharFromStr } from "../../utils/reuseableFunctions";
import MainLoader from "./MainLoader";
import { useMyContext } from "../../ContextApi";

const baseUrl = process.env.REACT_APP_API_URL;

export default function HistoryVComponent() {
  const token = sessionStorage.getItem("token");
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const { historyProposals, setHistoryProposals, historyRef } = useMyContext();
  const [activeEventId, setActiveEventId] = useState("");
  const [eventDetails, setEventDetails] = useState({
    title: "",
    date: "",
    description: "",
    offeredAmount: "",
    proposedAmount: "",
    declineReason: "",
    status: ""
  });
  async function handleClick(id: string) {
    setActiveEventId(id);
    try {
      const response = await axios.get(`${baseUrl}/api/proposal/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === 200) {
        setEventDetails({
          title: response.data.proposal.title,
          date: response.data.proposal.time,
          description: response.data.proposal.description,
          offeredAmount: response.data.proposal.offeredAmount,
          proposedAmount: response.data.proposal.proposedAmount,
          declineReason: response.data.proposal.declineReason,
          status: response.data.proposal.status
        });
      } else {
        setEventDetails({
          title: "",
          date: "",
          description: "",
          offeredAmount: "",
          proposedAmount: "",
          declineReason: "",
          status: ""
        });
      }
    } catch (error) {
      setEventDetails({
        title: "",
        date: "",
        description: "",
        offeredAmount: "",
        proposedAmount: "",
        declineReason: "",
        status: ""
      });
    }
    if (window.innerWidth > 768) return;
    setIsEventDetailsVisible(true);
  }
  function handleClick2() {
    setIsEventDetailsVisible(false);
  }
  async function getAllProposals() {
    try {
      const response = await axios.get(`${baseUrl}/api/proposals/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setHistoryProposals(() => {
          return response.data.proposals.filter(({ time }: { time: string }) => {
            const currentTime = dayjs();
            const differenceInDays = dayjs(time).diff(currentTime, "day");
            return differenceInDays <= 0;
          });
        });
      } else {
        setHistoryProposals([]);
      }
    } catch (error) {
      setHistoryProposals([]);
    }
  }


  useEffect(() => {
    if (!historyRef.current) {
      getAllProposals();
      historyRef.current = true;
    }
  }, []);

  return (
    <>
      {historyProposals.filter(({ time, status }) => {
        const currentTime = dayjs();
        const differenceInDays = dayjs(time).diff(currentTime, "day");
        return differenceInDays == 0 && (status == "accepted" || status == "declined");
      }).length > 0 ||
      historyProposals.filter(({ time, status }) => {
        const currentTime = dayjs();
        const differenceInDays = dayjs(time).diff(currentTime, "day");
        return differenceInDays == -1 && (status == "accepted" || status == "declined");
      }).length > 0 ||
      historyProposals.filter(({ time, status }) => {
        const currentTime = dayjs();
        const differenceInDays = dayjs(time).diff(currentTime, "day");
        return differenceInDays < -1 && differenceInDays >= -7 && (status == "accepted" || status == "declined");
      }).length > 0 ||
      historyProposals.filter(({ time, status }) => {
        const currentTime = dayjs();
        const differenceInDay = dayjs(time).diff(currentTime, "day");
        return differenceInDay <= -8 && differenceInDay >= -30 && (status == "accepted" || status == "declined");
      }).length > 0 ? (
        <div className='history-page'>
          {!isEventDetailsVisible ? (
            <>
              <HeaderComponent pageName='History' count={count} setCount={setCount} />
              <div className='history-page-container'>
                <div className='history-container-1'>
                  <div>
                    {historyProposals.filter(({ time, status }) => {
                      const currentTime = dayjs();
                      const differenceInDays = dayjs(time).diff(currentTime, "day");
                      return differenceInDays == 0 && (status == "accepted" || status == "declined");
                    }).length > 0 && <p>Today</p>}
                    <div>
                      {historyProposals
                        .filter(({ time, status }) => {
                          const currentTime = dayjs();
                          const differenceInDays = dayjs(time).diff(currentTime, "day");
                          return differenceInDays == 0 && (status == "accepted" || status == "declined");
                        })
                        .map(({ _id, vendorId, status }, index) => {
                          return (
                            <>
                              <div key={index} className={_id == activeEventId ? "active" : "non-active"} onClick={() => handleClick(_id)}>
                                <img src={image} alt='protrait of user' />
                                <p>{extractCharFromStr(vendorId, 10)}</p>
                                <div>
                                  <svg
                                    className={status}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                  >
                                    <circle cx='5' cy='5' r='5' fill='#F41C1C' />
                                  </svg>
                                  <p>{status}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    {historyProposals.filter(({ time, status }) => {
                      const currentTime = dayjs();
                      const differenceInDays = dayjs(time).diff(currentTime, "day");
                      return differenceInDays == -1 && (status == "accepted" || status == "declined");
                    }).length > 0 && <p>Yesterday</p>}
                    <div>
                      {historyProposals
                        .filter(({ time, status }) => {
                          const currentTime = dayjs();
                          const differenceInDays = dayjs(time).diff(currentTime, "day");
                          return differenceInDays == -1 && (status == "accepted" || status == "declined");
                        })
                        .map(({ _id, vendorId, status }, index) => {
                          return (
                            <>
                              <div key={index} className={_id == activeEventId ? "active" : "non-active"} onClick={() => handleClick(_id)}>
                                <img src={image} alt='protrait of user' />
                                <p>{extractCharFromStr(vendorId, 10)}</p>
                                <div>
                                  <svg
                                    className={status}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                  >
                                    <circle cx='5' cy='5' r='5' fill='#F41C1C' />
                                  </svg>
                                  <p>{status}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    {historyProposals.filter(({ time, status }) => {
                      const currentTime = dayjs();
                      const differenceInDays = dayjs(time).diff(currentTime, "day");
                      return differenceInDays < -1 && differenceInDays >= -7 && (status == "accepted" || status == "declined");
                    }).length > 0 && <p>Last week</p>}
                    <div>
                      {historyProposals
                        .filter(({ time, status }) => {
                          const currentTime = dayjs();
                          const differenceInDays = dayjs(time).diff(currentTime, "day");
                          return differenceInDays < -1 && differenceInDays >= -7 && (status == "accepted" || status == "declined");
                        })
                        .map(({ _id, vendorId, status }, index) => {
                          return (
                            <>
                              <div key={index} className={_id == activeEventId ? "active" : "non-active"} onClick={() => handleClick(_id)}>
                                <img src={image} alt='protrait of user' />
                                <p>{extractCharFromStr(vendorId, 10)}</p>
                                <div>
                                  <svg
                                    className={status}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                  >
                                    <circle cx='5' cy='5' r='5' fill='#F41C1C' />
                                  </svg>
                                  <p>{status}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    {historyProposals.filter(({ time, status }) => {
                      const currentTime = dayjs();
                      const differenceInDay = dayjs(time).diff(currentTime, "day");
                      return differenceInDay <= -8 && differenceInDay >= -30 && (status == "accepted" || status == "declined");
                    }).length > 0 && <p>Last month</p>}
                    <div>
                      {historyProposals
                        .filter(({ time, status }) => {
                          const currentTime = dayjs();
                          const differenceInDay = dayjs(time).diff(currentTime, "day");
                          return differenceInDay <= -8 && differenceInDay >= -30 && (status == "accepted" || status == "declined");
                        })
                        .map(({ _id, vendorId, status }, index) => {
                          return (
                            <>
                              <div key={index} className={_id == activeEventId ? "active" : "non-active"} onClick={() => handleClick(_id)}>
                                <img src={image} alt='protrait of user' />
                                <p>{extractCharFromStr(vendorId, 10)}</p>
                                <div>
                                  <svg
                                    className={status}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                  >
                                    <circle cx='5' cy='5' r='5' fill='#F41C1C' />
                                  </svg>
                                  <p>{status}</p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
                  <>
                    {eventDetails.description && (
                      <div className='history-container-2 big-screen'>
                        <p>Event details</p>
                        <div>
                          <div>
                            <img src={image} alt='protrait of a user' />
                            <p>Abimbola oladele</p>
                          </div>
                          <div>
                            <div className='user-details'>
                              <p>Event title</p>
                              <p>{eventDetails.title}</p>
                            </div>
                            <div className='user-details'>
                              <p>Date</p>
                              <p>{dayjs(eventDetails.date).format(`Do, MMMM YYYY`)}</p>
                            </div>
                            <div className='user-details'>
                              <p>Description</p>
                              <p>{eventDetails.description}</p>
                            </div>
                            <div className='user-details'>
                              <p>My price</p>
                              <p>{eventDetails.proposedAmount}</p>
                            </div>
                            <div className='user-details'>
                              <p>planner&apos;s price</p>
                              <p>{eventDetails.offeredAmount}</p>
                            </div>
                            <div className='user-details status'>
                              <p>Status</p>
                              <p className={eventDetails.status}>{eventDetails.status}</p>
                            </div>
                            {eventDetails.status === "declined" && (
                              <div className='user-details'>
                                <p>Reason</p>
                                <p>{eventDetails.declineReason}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
              </div>
            </>
          ) : (
            <>
              {eventDetails.description && (
                <div className='history-container-2'>
                  <div className='event-cta'>
                    <svg onClick={handleClick2} width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M10.3724 0.98491C10.2563 0.868502 10.1184 0.776145 9.96653 0.713129C9.81467 0.650113 9.65186 0.617676 9.48744 0.617676C9.32303 0.617676 9.16022 0.650113 9.00836 0.713129C8.8565 0.776145 8.71856 0.868502 8.60244 0.98491L0.292444 9.29491C0.199741 9.38742 0.126193 9.49731 0.0760117 9.61829C0.0258302 9.73926 0 9.86894 0 9.99991C0 10.1309 0.0258302 10.2606 0.0760117 10.3815C0.126193 10.5025 0.199741 10.6124 0.292444 10.7049L8.60244 19.0149C9.09244 19.5049 9.88245 19.5049 10.3724 19.0149C10.8624 18.5249 10.8624 17.7349 10.3724 17.2449L3.13244 9.99491L10.3824 2.74491C10.8624 2.26491 10.8624 1.46491 10.3724 0.98491Z'
                        fill='#262626'
                      />
                    </svg>
                    <p>Event details</p>
                  </div>
                  <div>
                    <div>
                      <img src={image} alt='protrait of a user' />
                      <p>Abimbola oladele</p>
                    </div>
                    <div>
                      <div className='user-details'>
                        <p>Event title</p>
                        <p>{eventDetails.title}</p>
                      </div>
                      <div className='user-details'>
                        <p>Date</p>
                        <p>{dayjs(eventDetails.date).format(`Do, MMMM YYYY`)}</p>
                      </div>
                      <div className='user-details'>
                        <p>Description</p>
                        <p>{eventDetails.description}</p>
                      </div>
                      <div className='user-details'>
                        <p>My price</p>
                        <p>{eventDetails.proposedAmount}</p>
                      </div>
                      <div className='user-details'>
                        <p>planner&apos;s price</p>
                        <p>{eventDetails.offeredAmount}</p>
                      </div>
                      <div className='user-details status'>
                        <p>Status</p>
                        <p className={eventDetails.status}>{eventDetails.status}</p>
                      </div>
                      {eventDetails.status === "declined" && (
                        <div className='user-details'>
                          <p>Reason</p>
                          <p>{eventDetails.declineReason}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : !historyRef.current && historyProposals.length === 0 ? (
        <div className='no-history-page'>
          <MainLoader />
        </div>
      ) : (
        <div className='history-page' style={{ display: "flex", flexDirection: "column" }}>
          <HeaderComponent pageName='History' count={count} setCount={setCount} />
          <div className='no-history-page'>
            <p>No History</p>
            <p>No recent Proposals</p>
          </div>
        </div>
      )}
    </>
  );
}
