import React, { useState } from "react";
//import SidebarComponent from "../../SidebarComponent";
import SwitchRole from "../../SwitchRole";
// import "./PlannerSupportStyles.css";
// import Faq from "../../Faq";
// import PlannerHeader from "../Header/Header";
import PlannerSidebarComponent from "../PlannerSidebar";
import SupportVComponent from "../../SupportVComponent";
import { useMyContext } from "../../../../ContextApi";

export default function PlannerSupport() {
  const { setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("support");

  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='vendorpage' onClick={handleClick}>
      <PlannerSidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      {/* <div className="support-page">
				<div className="support-page-main">
					<PlannerHeader title={"Support"} hideArrow={true} />
					<div className="support-page-container">
						<div>
							<p>Account & app usage</p>
							<Faq
								question="How do i sign up and create account?"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
							<Faq
								question="Can I use app on multiple devices"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
							<Faq
								question="How do I update my profile information"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
						</div>
						<div>
							<p>Payment & Reviews</p>
							<Faq
								question="How do I get paid for bookings?"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
							<Faq
								question=" How do reviews and rating work"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
							<Faq
								question=" How do I get paid for bookings?"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
						</div>
						<div>
							<p>Bookings</p>
							<Faq
								question=" What happens if a booking is cancelled?"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
							<Faq
								question="How do I communicate with potential client?"
								answer="Nisi Lorem ullamco sunt culpa fugiat eu exercitation est nisi est ipsum."
							/>
						</div>
					</div>
				</div>
			</div> */}
      <SupportVComponent />
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planne'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}
