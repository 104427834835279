import React from "react";
import "./ReportModal.css";
import successSvg from "../../../../../assets/success-icon.svg";

interface ReportModalProps {
  message: string;
  description?: string;
  showBtn?: boolean;
  closeModal: () => void;
}

const EventModal = ({ message, description, closeModal }: ReportModalProps) => {
  return (
    <>
      <div className='planner-modal lg:h-[600px] p-4 flex flex-col justify-center'>
        <div className='event-success-image-container'>
          <img src={successSvg} alt='success-icon' className='sm:w-64' />
        </div>
        <h1 className='event-success-msg'>{message}</h1>
        <p className='event-description '>{description}</p>
        <div className='close-modal mr-2  sm:-mr-8' onClick={closeModal}>
          <img src='/close-icon.svg' alt='close-icon' />
        </div>
      </div>

      <div className='planner-modal-overlay' onClick={closeModal}></div>
    </>
  );
};

export default EventModal;
