import React, {useState } from 'react'
import { useMyContext } from '../../../ContextApi';
import SidebarComponent from '../../common/SidebarComponent';
import SwitchRole from '../../common/SwitchRole';
import TransactionVcomponent from '../../common/TransactionVcomponent'


export default function TransactionDetailPage() {
    const {setIsMobileSidebarOpen } = useMyContext();
    const [activeTab, setActiveTab] = useState("wallet");
    const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
    function handleClick(e: React.MouseEvent) {
        const targetImage = e.target as HTMLImageElement;
        if (targetImage.alt === "dashboard-image") return;
        setIsMobileSidebarOpen(false);
    }

  return (
    <div className='vendorpage' onClick={handleClick}>
        <SidebarComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            component='vendor'
            setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
        />

        <TransactionVcomponent />
        {isSwitchUserModalOpen && (
        <SwitchRole
            isSwitchUserModalOpen={isSwitchUserModalOpen}
            setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
            component='vendor'
            title='Do you want switch to planner mode?'
            description='To create an event, you need to switch to the planner.'
            btnText1='Continue as Vendor'
            btnText2='Switch to Planner'
        />
        )}
    </div>
  )
}
