import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
// import { useMyContext } from "../ContextApi";

const firebaseConfig = {
  apiKey: "AIzaSyC39YkabLEDARS3xltHladI-49k9H7G7lI",
  authDomain: "partypal-74c39.firebaseapp.com",
  projectId: "partypal-74c39",
  messagingSenderId: "91521563379",
  appId: "1:91521563379:web:1e9da0c21e94bf12661586",
  measurementId: "G-46JHXGG8W8",
  storageBucket: "partypal-74c39.appspot.com"
};

const publicVapidKey = "BByUsNx1K0OL_ZLF2A5MTMSJaZa3taALiXWtgGBs8ZDBvHzhXvZ8kR5pysvMiSCE5uqPx_oiOotJXaCeuvL52nw";
// const privateVapidKey = "mpqJ1tAHVU69K3N2Rnm7F4xdltybHjlWTt4ZMznyPPA";
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFCMToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, { vapidKey: publicVapidKey });
      // console.log("FCM token refreshed: ", token);
      return token;
    } else {
      throw new Error("Notification permission denied");
    }
  } catch (err) {
    console.error("Error getting FCM token", err);
    throw err;
  }
};

onMessage(messaging, (payload) => {
  console.log("Message received: ", payload);

  const { title, body, icon } = payload.notification || {};

  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration) {
      registration.showNotification(title || "Title", {
        body: body || "Body",
        icon: icon || "../../public/notification1.png",
        data: payload.data || {}
      });
    } else {
      console.error("Service Worker registration not found.");
    }
  });
});
