import React, { useState, useEffect } from "react";
//import profilepic from "../../assets/profilepic.svg";
import plannerpic from "../../assets/plannerpic.svg";
import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import { formatPrice } from "../../utils/reuseableFunctions";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/AppRoutes";
import { useMyContext } from "../../ContextApi";
import MainLoader from "./MainLoader";

const baseUrl = process.env.REACT_APP_API_URL;

export default function OfferVComponent() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const { proposals, setProposals, offerRef } = useMyContext();

  const handleClick = (id: string) => {
    navigate(AppRoutes.V_OFFER_DETAILS.replace(":offerId", id));
  };

  async function getAllProposals() {
    try {
      const response = await axios.get(`${baseUrl}/api/proposals/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposals(response.data.proposals);
        offerRef.current = true;
      } else {
        setProposals([]);
        offerRef.current = true;
      }
    } catch (error) {
      setProposals([]);
      offerRef.current = true;
    }
  }


  useEffect(() => {
    if (!offerRef.current) {
      getAllProposals();
    }
  }, []);

  return (
    <div className='offer-page'>
      {proposals.length < 1 && !offerRef.current ? (
        <div className='flex-1 h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      ) : (
        <div className='offer-main'>
          <HeaderComponent pageName='Offer' count={count} setCount={setCount} />
              <>
                {proposals.length > 0 ? (
                  <div className='order-sometimes offer'>
                    <table>
                      <thead>
                      <tr>
                        <th>Planner</th>
                        <th>Planner&apos;s price</th>
                        <th>My price</th>
                        <th>Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      {proposals.map(({ planner, _id, offeredAmount, proposedAmount, status }) => {
                        return (
                          <tr onClick={() => handleClick(_id)} key={_id}>
                            <td>
                              <img src={plannerpic} alt='planner picture' />
                              <p>{planner}</p>
                            </td>
                            <td>{formatPrice(offeredAmount.toString())}</td>
                            <td>{formatPrice(proposedAmount.toString())}</td>
                            <td className='accepted'>
                              <div className={status}>{status}</div>
                              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='none'>
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M20.502 15.0243C20.752 15.2744 20.8924 15.6134 20.8924 15.967C20.8924 16.3205 20.752 16.6596 20.502 16.9097L12.9594 24.4523C12.8364 24.5797 12.6893 24.6812 12.5266 24.7511C12.3639 24.821 12.189 24.8578 12.0119 24.8593C11.8349 24.8609 11.6593 24.8271 11.4954 24.7601C11.3316 24.693 11.1827 24.594 11.0575 24.4689C10.9323 24.3437 10.8333 24.1948 10.7663 24.0309C10.6992 23.8671 10.6655 23.6915 10.667 23.5145C10.6686 23.3374 10.7054 23.1625 10.7752 22.9998C10.8451 22.8371 10.9467 22.69 11.074 22.567L17.674 15.967L11.074 9.36699C10.8312 9.11552 10.6968 8.77872 10.6998 8.42912C10.7028 8.07953 10.8431 7.74511 11.0903 7.4979C11.3375 7.25069 11.6719 7.11046 12.0215 7.10742C12.3711 7.10439 12.7079 7.23878 12.9594 7.48166L20.502 15.0243Z'
                                  fill='#5F5F5F'
                                />
                              </svg>
                            </td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className='no-offer-page desktop'>
                    <p>No Offer</p>
                    <p>No Recent Offer</p>
                  </div>
                )}
              </>
              <>
                {
                  proposals.length > 0 
                  ? (
                    <div className='offer-mobile'>
                    {proposals.map(({ planner, _id, status }) => {
                      return (
                        <div onClick={() => handleClick(_id)} key={_id}>
                          <div>
                            <img src={plannerpic} alt='planner picture' />
                            <p>{planner}</p>
                          </div>
                          <div className="flex gap-6 items-center">
                            <div  className={`${status} offer-status`}>{status}</div>
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='none'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M20.502 15.0243C20.752 15.2744 20.8924 15.6134 20.8924 15.967C20.8924 16.3205 20.752 16.6596 20.502 16.9097L12.9594 24.4523C12.8364 24.5797 12.6893 24.6812 12.5266 24.7511C12.3639 24.821 12.189 24.8578 12.0119 24.8593C11.8349 24.8609 11.6593 24.8271 11.4954 24.7601C11.3316 24.693 11.1827 24.594 11.0575 24.4689C10.9323 24.3437 10.8333 24.1948 10.7663 24.0309C10.6992 23.8671 10.6655 23.6915 10.667 23.5145C10.6686 23.3374 10.7054 23.1625 10.7752 22.9998C10.8451 22.8371 10.9467 22.69 11.074 22.567L17.674 15.967L11.074 9.36699C10.8312 9.11552 10.6968 8.77872 10.6998 8.42912C10.7028 8.07953 10.8431 7.74511 11.0903 7.4979C11.3375 7.25069 11.6719 7.11046 12.0215 7.10742C12.3711 7.10439 12.7079 7.23878 12.9594 7.48166L20.502 15.0243Z'
                                fill='#5F5F5F'
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  </div>                   
                  ) 
                  : (
                    <div className='no-offer-page mobile'>
                    <p>No Offer</p>
                    <p>No Recent Offer</p>
                  </div>
                  )
                }
              </>
        </div>
      )}
    </div>
  );
}
