import React from "react";
import rejectSvg from "../../../../../assets/rejectSvg.svg"
import "../SuccessModal/SuccessModalStyles.css"

interface WalletDeclineProps{
    message: string;
    description: string;
    btnText: string;
    closeModal: ()=> void
}

export default function WalletDecline({message,description,btnText,closeModal} : WalletDeclineProps){
    return (
        <>
            <div className='planner-modal success-modal'>
            <div className='success-image-container'>
                <img src={rejectSvg} alt='success-icon' />
            </div>
            <p style={{color:"#1c5d99"}} className="success-msg font-bold">{message}</p>
            <p style={{color:"#000000"}} className="font-semibold mb-3">{description}</p>
            <button className="py-2 px-12 rounded-lg bg-[#1c5d99] text-white hover:opacity-85" onClick={closeModal}>{btnText}</button>
            </div>
            <div className='planner-modal-overlay' onClick={closeModal}></div>
        </>
    )
}