import React, { useState } from "react";

interface faqComponentProps {
  question: string;
  answer: string;
}

export default function Faq({ question, answer }: faqComponentProps) {
  const [isFaqOpen, setIsFaqOpen] = useState(false);

  function handleClick() {
    setIsFaqOpen(!isFaqOpen);
  }
  return (
    <div className='faq'>
      <p className='faq-question' onClick={handleClick}>
        {question}
      </p>
      {isFaqOpen && <p className='faq-answer'>{answer}</p>}
      <svg
        className={isFaqOpen ? "open" : "close"}
        onClick={handleClick}
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='33'
        viewBox='0 0 32 33'
        fill='none'
      >
        <path
          d='M7.35552 11.4986C7.23911 11.6148 7.14675 11.7527 7.08373 11.9046C7.02072 12.0564 6.98828 12.2192 6.98828 12.3836C6.98828 12.5481 7.02072 12.7109 7.08373 12.8627C7.14675 13.0146 7.23911 13.1525 7.35552 13.2686L15.6655 21.5786C15.758 21.6714 15.8679 21.7449 15.9889 21.7951C16.1099 21.8453 16.2395 21.8711 16.3705 21.8711C16.5015 21.8711 16.6312 21.8453 16.7521 21.7951C16.8731 21.7449 16.983 21.6714 17.0755 21.5786L25.3855 13.2686C25.8755 12.7786 25.8755 11.9886 25.3855 11.4986C24.8955 11.0086 24.1055 11.0086 23.6155 11.4986L16.3655 18.7386L9.11552 11.4887C8.63552 11.0087 7.83552 11.0086 7.35552 11.4986Z'
          fill='#1C5D99'
        />
      </svg>
    </div>
  );
}
