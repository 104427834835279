import React, { Dispatch, SetStateAction, useState } from "react";
//import profilepic from "../../assets/profilepic.svg";
import HeaderComponent from "./HeaderComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Spinner } from "./loader";

const baseUrl = process.env.REACT_APP_API_URL;

interface JwtPayload {
  user: {
    _id: string;
  };
}

interface passwordComponentProps {
  passwordModal: boolean;
  setPasswordModal: Dispatch<SetStateAction<boolean>>;
}



export default function PasswordVComponent({ setPasswordModal }: passwordComponentProps) {
  const [count, setCount] = useState(0);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("previous password required"),
    newPassword: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword", undefined), undefined] as const, "Passwords must match")
      .required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const token = sessionStorage.getItem("token");
      let user_id;
      if (token !== null) {
        const decoded = jwtDecode<JwtPayload>(token);
        user_id = decoded.user._id;
      }
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${baseUrl}/api/users/changepassword/${user_id}`,
          {
            ...values
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.status == 200) {
          setIsLoading(false);
          setPasswordModal(true);
          resetForm();
        }
      } catch (error) {
        setIsLoading(false);
  
      }
    }
  });

  return (
    <div className='password-page'>
      <div className='password-page-main'>
        <HeaderComponent pageName='Change Password' count={count} setCount={setCount} />

        <div className='password-container'>
          <p>You can change your password by imputing the previous one</p>
          <form className='password-form' onSubmit={formik.handleSubmit}>
            <div className='passworddd'>
              <input
                type={!isPasswordVisible ? "password" : "text"}
                placeholder='Previous password'
                name='currentPassword'
                id='currentPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
              />
              <div
                className='icon-container'
                onClick={() => {
                  setIsPasswordVisible((prev) => !prev);
                }}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
                    fill='#1C5D99'
                  />
                  <path
                    d='M18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C9.94078 4.5 7.94438 5.10937 6.05484 6.30375C4.14937 7.51078 2.28141 9.70312 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C14.2856 19.5 16.4817 18.7936 18.5184 17.4005C20.3114 16.1719 22.0177 14.0541 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5Z'
                    fill='#1C5D99'
                  />
                </svg>
                {!isPasswordVisible && <span className='line'></span>}
              </div>
              {formik.touched.currentPassword && formik.errors.currentPassword ? <div>{formik.errors.currentPassword}</div> : null}
            </div>
            <div className='passworddd'>
              <input
                type='password'
                placeholder='New password'
                name='newPassword'
                id='newPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? <div>{formik.errors.newPassword}</div> : null}
            </div>
            <div className='passworddd confirm'>
              <input
                type='password'
                placeholder='confirm password'
                name='confirmPassword'
                id='confirmPassword'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div>{formik.errors.confirmPassword}</div> : null}
            </div>
            <button type='submit'>{isLoading ? <Spinner /> : "Save changes"}</button>
          </form>
        </div>
      </div>
    </div>
  );
}
