import React, { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import ReportEventComponent from "../../common/ReportEventComponent";
import SwitchRole from "../../common/SwitchRole";
import { useMyContext } from "../../../ContextApi";

export default function ReportEventPage() {
  const { setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("orderrequest");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component={"vendor"}
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <ReportEventComponent />
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
    </div>
  );
}
