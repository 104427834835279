import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { jwtDecode, JwtPayload } from "jwt-decode";
import axios from "axios";
import { AppRoutes } from "../../utils/AppRoutes";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./loader";
import Cookies from "js-cookie";
import { useMyContext } from "../../ContextApi";

const baseUrl = process.env.REACT_APP_API_URL;

const Endpoint1 = `${baseUrl}/api/categories`;

interface CustomJwtPayload extends JwtPayload {
  user: {
    _id: string;
  };
}

interface switchRoleProps {
  isSwitchUserModalOpen?: boolean;
  setIsSwitchUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  component: string;
  title?: string;
  description?: string;
  btnText1?: string;
  btnText2?: string;
}

export default function SwitchRole({
  setIsSwitchUserModalOpen,
  component,
  title,
  description,
  btnText1,
  btnText2
}: switchRoleProps) {
  const token = sessionStorage.getItem("token");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobCategories, setJobCategories } = useMyContext();

  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.className === "switch-role-modal" || targetImage.className === "btn-vendor") {
      setIsSwitchUserModalOpen(false);
    }
  }

  const validationSchema = Yup.object({
    jobCategory: Yup.string().required("job category must be specified")
  });

  async function switchRole() {
    if (component == "planner") {
      setCount(1);
    } else {
      if (token) {
        const decoded: CustomJwtPayload = jwtDecode(token);
        const Endpoint = `${baseUrl}/api/users/${decoded.user._id}`;
        try {
          setIsLoading(true);
          const response = await axios.patch(
            Endpoint,
            {
              currentRole: "planner"
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          if (response.data.status === 200) {
            setIsLoading(false);
            Cookies.set("currentRole", "planner");
            navigate(AppRoutes.PLANNER.HOME);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  }
  function switchUserRole() {
    formik.handleSubmit();
  }

  const formik = useFormik({
    initialValues: {
      jobCategory: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (token) {
        const decoded: CustomJwtPayload = jwtDecode(token);
        const Endpoint = `${baseUrl}/api/users/${decoded.user._id}`;
        try {
          setIsLoading(true);
          const response = await axios.patch(
            Endpoint,
            {
              jobCategory: values.jobCategory,
              currentRole: "vendor"
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          if (response.data.status === 200) {
            setIsLoading(false);
            Cookies.set("currentRole", "vendor");
            navigate(AppRoutes.V_DASH);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  });

  const getJobCategories = async (signal: AbortSignal) => {
    try {
      const response = await axios.get(Endpoint1, {
        signal: signal
      });
      if (response.status === 200) {
        setJobCategories(response.data);
      } else {
        setJobCategories([]);
      }
    } catch (error) {
      setJobCategories([]);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (jobCategories.length === 0) {
      getJobCategories(signal);
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='switch-role-modal' onClick={handleClick2}>
      <div className='switch-role-modal-main'>
        {count == 0 ? (
          <>
            <p className='switch-title'>{title}</p>
            <p className='switch-desc'>{description}</p>
            <div className='switch-button'>
              <button className='btn-vendor'>{btnText1}</button>
              <button className='btn-planner' onClick={switchRole}>
                {isLoading ? <Spinner /> : btnText2}
              </button>
            </div>
          </>
        ) : count == 1 && component == "planner" ? (
          <div className='category' style={{ transform: "translateY(0%)" }}>
            <p className='category-desc'>switch to vendor</p>
            <select name='jobCategory' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.jobCategory}>
              <option value='' disabled selected>
                -- choose a category --
              </option>
              {jobCategories.map(({ _id, name }) => {
                return (
                  <option key={_id} value={name}>
                    {name}
                  </option>
                );
              })}
            </select>
            {formik.errors.jobCategory && formik.touched.jobCategory ? <p className='error'>kindly select a job category</p> : ""}
            <button className='intro1-btn' type='submit' onClick={switchUserRole}>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <span>Continue</span>
                  <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                      d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
