import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppReduxStore from "./redux-store/AppReduxStore";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// @ts-ignore PWAprompt
import PWAPrompt from "react-ios-pwa-prompt";
import { MyContextProvider } from "./ContextApi";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId= '82104124310-tus8ane7r1p1hhtftdktjqsse98qhpgb.apps.googleusercontent.com'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={AppReduxStore}>
      <BrowserRouter>
        <MyContextProvider>
          <GoogleOAuthProvider clientId={clientId}>
            <App />
          </GoogleOAuthProvider>
        </MyContextProvider>
        <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt='Close' permanentlyHideOnDismiss={false} />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch(function (err) {
      console.error("Service Worker registration failed:", err);
    });
}

// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
