import React from "react";
import { useState } from "react";
import "./PlannerHistoryStyles.css";
import PlannerSidebarComponent from "../PlannerSidebar";

import SwitchRole from "../../SwitchRole";
import HistoryPComponent from "./HistoryPComponent";
import { useMyContext } from "../../../../ContextApi";

const PlannerHistory = () => {

  const [activeTab, setActiveTab] = useState("history");
  const { setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);


  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='planner-history-page' onClick={handleClick}>
      <PlannerSidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <HistoryPComponent />
      {/* <div className="planner-history-container">
				<PlannerHeader title="History" hideArrow={true} />

				<div
					className="planner-history"
					style={{ display: selectedEvent ? "flex" : "block" }}
				>
					<div className="history-box">
						{history.map(({ day, details }, index) => (
							<div key={index}>
								<p className="history-period">{day}</p>
								{details.map(({ picture, name, status, id }) => (
									<>
										<div
											className="history-event"
											key={index}
											onClick={() => openEvent(id, name, picture, status)}
											style={{
												backgroundColor:
													selectedEvent === id ? "#DDFFE0E5" : "",
											}}
										>
											<img src={`/${picture}.svg`} alt="profile-pic" />
											<p className="history-event-name">{name}</p>
											<div className="status-container">
												<span
													style={{
														backgroundColor:
															status === "Declined"
																? "#F41C1C"
																: status === "Successful"
																? "#6EB424"
																: "#1C5D99",
													}}
												></span>
												<p>{status}</p>
											</div>
										</div>
									</>
								))}
							</div>
						))}
					</div>
					{selectedEvent && (
						<HistoryEventDetails
							name={selectedEventDetails.name}
							picture={selectedEventDetails.picture}
							status={selectedEventDetails.status}
							closeEvent={closeEvent}
						/>
					)}
				</div>
			</div> */}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
};

export default PlannerHistory;
