import React, { useEffect, useState } from "react";
import Image from "../../assets/request-image.svg";
import HeaderComponent from "./HeaderComponent";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { dayPosition, formatPrice } from "../../utils/reuseableFunctions";
import { AppRoutes } from "../../utils/AppRoutes";
import MainLoader from "./MainLoader";

const baseUrl = process.env.REACT_APP_API_URL;

export default function OfferDetailsComponent() {
  const token = sessionStorage.getItem("token");
  const { offerId } = useParams();
  const navigate = useNavigate()
  const [count, setCount] = useState(1);
  const [proposalDetails, setProposalDetails] = useState({
    planner: "",
    description: "",
    venue: "",
    date: "",
    proposedAmount: 0,
    offeredAmount: 0,
    status: "",
    declineReason: "",
    offerId: ""
  });


  async function getSpecificProposal() {
    try {
      const response = await axios.get(`${baseUrl}/api/proposal/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposalDetails({
          planner: response.data.proposal.planner,
          description: response.data.proposal.description,
          venue: response.data.proposal.venue,
          date: response.data.proposal.date,
          proposedAmount: response.data.proposal.proposedAmount,
          offeredAmount: response.data.proposal.offeredAmount,
          status: response.data.proposal.status,
          declineReason: response.data.proposal.declineReason,
          offerId: response.data.proposal.offerId
        });
      } else {
        setProposalDetails({
          planner: "",
          description: "",
          venue: "",
          date: "",
          proposedAmount: 0,
          offeredAmount: 0,
          status: "",
          declineReason: "",
          offerId: ""
        });
      }
    } catch (error) {
      setProposalDetails({
        planner: "",
        description: "",
        venue: "",
        date: "",
        proposedAmount: 0,
        offeredAmount: 0,
        status: "",
        declineReason: "",
        offerId: ""
      });
    }
  }


  useEffect(() => {
    if(proposalDetails.description == "")
      getSpecificProposal();
  }, []);

  return (
    <div className='offer-page'>
      <div className='offer-main offer-details'>
        <HeaderComponent pageName='Offer' count={count} setCount={setCount} />
        {proposalDetails.description != "" ? (
          <div className="container-offer-component">
          <div className='offer-component'>
            <div>
              <div>
                <img src={Image} alt='Request Image' />
                <p>{proposalDetails.planner}</p>
              </div>
              <div>
                <div>
                  <p>Location</p>
                  <p>{proposalDetails.venue}</p>
                </div>
                <div>
                  <p>Time</p>
                  <p>{dayjs(proposalDetails.date).format("h:mm A")}</p>
                </div>
                <div>
                  <p>Date</p>
                  <p>{dayjs(proposalDetails.date).format(`D[${dayPosition(proposalDetails.date)}], MMMM YYYY`)}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>Description</p>
                  <p>
                    {proposalDetails.description}
                  </p>
                </div>
                <div>
                  <p>Price</p>
                  <p>{formatPrice(proposalDetails.offeredAmount.toString())}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>My price</p>
                  <p>{formatPrice(proposalDetails.offeredAmount.toString())}</p>
                </div>
                <div>
                  <p>Status</p>
                  <p className={proposalDetails.status}>{proposalDetails.status}</p>
                </div>
                {proposalDetails.declineReason !== undefined
                &&
                <div>
                  <p>Reason</p>
                  <p>{proposalDetails.declineReason}</p>
                </div>
                }
              </div>
              {proposalDetails.status === "declined"
              ?
              <div>
                <p>Would you like to offer a different price?</p>
                <button
                  onClick={() => {
                    if(proposalDetails.offerId){
                    navigate(AppRoutes.V_ORDER_REQUEST.replace(":offerId", proposalDetails.offerId))

                  }
                  }
                }
                >Resend proposal</button>
              </div>
              : proposalDetails.status === "accepted"
              ? <div>
                  <p>This event has been added to your calendar</p>
                </div>
              : <div>
                  <p>you will receive notification&apos;s once vendor accept</p>
                </div>
              }
            </div>
          </div>
          </div>
        ) : (
          <div className='grow flex-1 h-[100vh] flex justify-center items-center'>
            <MainLoader />
          </div>
        )}
      </div>
    </div>
  );
}
