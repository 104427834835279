import dayjs from "dayjs";

function extractCharFromStr(str: string, num: number) {
  const newStr = str.substring(0, num);
  return newStr;
}
function timeDifference(targetTime: string) {
  const currentTime = dayjs();
  const differenceInDays = dayjs(targetTime).diff(currentTime, "day");
  if (differenceInDays === 0 && dayjs(targetTime).diff(currentTime, "hour") <= 0) {
    return "Today";
  } else if (differenceInDays === 1 || (differenceInDays === 0 && dayjs(targetTime).diff(currentTime, "hour") > 0)) {
    return "Tomorrow";
  } else if (differenceInDays > 1 && differenceInDays < 7) {
    return `in ${differenceInDays} days`;
  } else if (differenceInDays >= 7 && differenceInDays < 30) {
    const differenceInWeeks = Math.floor(differenceInDays / 7);
    return `in ${differenceInWeeks} week${differenceInWeeks > 1 ? "s" : ""}`;
  } else if (differenceInDays >= 30 && differenceInDays < 365) {
    const differenceInMonths = Math.floor(differenceInDays / 30);
    return `in ${differenceInMonths} month${differenceInMonths > 1 ? "s" : ""}`;
  } else {
    const differenceInYears = Math.floor(differenceInDays / 365);
    return `in ${differenceInYears} year${differenceInYears > 1 ? "s" : ""}`;
  }
}

const formatPrice = (inputValue: string) => {
  const numericValue = inputValue.replace(/[^0-9]/g, "");

  const formattedValue = `₦ ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

  return formattedValue;
};

const dayPosition = (date: string | Date) => {
  const day = dayjs(date).format("D").toString();
  const digitArray = day.split("");
  if ((parseInt(day) >= 4 && parseInt(day) <= 9) || (parseInt(day) >= 10 && parseInt(day) <= 13)) {
    return "th";
  } else if (digitArray[digitArray.length - 1] == "1") {
    return "st";
  } else if (digitArray[digitArray.length - 1] == "2") {
    return "nd";
  } else if (digitArray[digitArray.length - 1] == "3") {
    return "rd";
  } else {
    return "th";
  }
};
const notificationTimeDifference = (time: string) => {
  const currentTime = dayjs();
  const timedifference = dayjs(time).diff(currentTime, "minute");
  if (timedifference == 0) {
    return "Now";
  } else if (timedifference < 0 && timedifference > -60) {
    return `${-1 * timedifference} minute${timedifference == -1 ? "" : "s"} ago`;
  } else {
    const timedifference = dayjs(time).diff(currentTime, "hour");
    if (timedifference < 0 && timedifference > -24) {
      return `${-1 * timedifference} hour${timedifference == -1 ? "" : "s"} ago`;
    } else {
      const timedifference = dayjs(time).diff(currentTime, "day");
      if(timedifference === -1){
        return "Yesterday"
      }else if(timedifference < -1 && timedifference >= -7) {
        return `${-1 * timedifference}day${timedifference == -1 ? "" : "s"} ago`;
      } else {
        const date = dayjs(time);
        return date.format(`h:mma, D[${dayPosition(time)}] of MMMM`);
      }
    }
  }
};

const walletTimeDifference = (time: string) => {
  const currentTime = dayjs();
  const timedifference = dayjs(time).diff(currentTime, "day");
  if (timedifference == 0) {
    const differenceInday = dayjs().date() - dayjs(time).date()
    if(differenceInday == 1){
      return "Yesterday"
    }
      return "Today"
    } else {
      const timedifference = dayjs(time).diff(currentTime, "day");
      if(timedifference === -1){
        return "Yesterday"
      }else {
        const date = dayjs(time);
        return date.format(`D[${dayPosition(time)}] of MMMM`);
      }
    }
  };

export { timeDifference, extractCharFromStr, formatPrice, notificationTimeDifference, walletTimeDifference, dayPosition};
