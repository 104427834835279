import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import picture from "../../assets/ellipse-200.svg";
import SwitchRole from "./SwitchRole";
import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { useParams } from "react-router-dom";


const baseUrl = process.env.REACT_APP_API_URL;
const token = sessionStorage.getItem("token");
dayjs.extend(customParseFormat);


type event = {
  datetime: string;
  isCompleted: boolean;
  offers: string[];
  plannerId: string;
  proposals: string[];
  skills: string[];
  title: string;
  vendors: string[];
  venue: string;
  _id: string;
};

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface calendarComponentProps {
  isSwitchUserModalOpen: boolean;
  component: string;
  setIsSwitchUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


export default function CalendarVComponent({ isSwitchUserModalOpen, component, setIsSwitchUserModalOpen }: calendarComponentProps) {
  dayjs.extend(customParseFormat);
  const { datetime } = useParams();
  const [value, onChange] = useState<Value>(new Date());
  const [count, setCount] = useState(1);
  const [copyToClipboard] = useState({
    name: "Fumilayo maxwell",
    title: "Mummy's 50th birthday",
    location: "11 monsuro street, Abule-oja, lagos",
    Date: "26th of November,2023",
    Description: "blahhhhhhhhh",
    phoneNumber: "08061647872",
    time: "10:00am",
    price: "N50,000"
  });
  const [isCopied, setIsCopied] = useState(false);
  const [eventDetails, setEventDetails] = useState<event[]>([]);
  const [EventDateDay, setEventDateDay] = useState(datetime);

  async function handleToClipboard() {
    const textToClipString = JSON.stringify(copyToClipboard);
    try {
      await navigator.clipboard.writeText(textToClipString);
      setIsCopied(true);
    } catch (err) {
      console.log("unable to copy to clipboard", err);
    }
  }
  async function getEventDetails(date: Date) {
    if (token) {
      try {
        const unixDate = dayjs(date).format("YYYY-MM-DD[T00:00:00.000Z]");
        //const decoded: CustomJwtPayload = jwtDecode(token);
        //const plannerId = decoded.user._id;
        const response = await axios.get(`${baseUrl}/api/events/search?datetime=${unixDate}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setEventDetails(response.data);
        }
      } catch (error) {
        setEventDetails([]);
      }
    }
  }

  useEffect(() => {
    const newDate = dayjs(datetime).toDate();
    setEventDateDay(newDate.toString());
    onChange(newDate);
    getEventDetails(newDate);
  }, []);

  return (
    <div className='dashboard-main'>
      {/* <div className='dashboard-main-heading'>
            <div 
                className='calendar-header'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none">
                    <path d="M10.3724 0.98491C10.2563 0.868502 10.1184 0.776145 9.96653 0.713129C9.81467 0.650113 9.65186 0.617676 9.48744 0.617676C9.32303 0.617676 9.16022 0.650113 9.00836 0.713129C8.8565 0.776145 8.71856 0.868502 8.60244 0.98491L0.292444 9.29491C0.199741 9.38742 0.126193 9.49731 0.0760117 9.61829C0.0258302 9.73926 0 9.86894 0 9.99991C0 10.1309 0.0258302 10.2606 0.0760117 10.3815C0.126193 10.5025 0.199741 10.6124 0.292444 10.7049L8.60244 19.0149C9.09244 19.5049 9.88245 19.5049 10.3724 19.0149C10.8624 18.5249 10.8624 17.7349 10.3724 17.2449L3.13244 9.99491L10.3824 2.74491C10.8624 2.26491 10.8624 1.46491 10.3724 0.98491Z" fill="#262626"/>
                </svg>
                <p className='calendar-text'>Calendar</p>
            </div>
        </div> */}
      <HeaderComponent pageName='Calendar' count={count} setCount={setCount} />
      <div className='dashboard-main-content2'>
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            className='main-calender'
            onClickDay={async (value) => {
              if (token) {
                setEventDateDay(value.toString());
                try {
                  const unixDate = dayjs(value).format("YYYY-MM-DD[T00:00:00.000Z]");
                  //const decoded: CustomJwtPayload = jwtDecode(token);
                  //const plannerId = decoded.user._id;
                  const response = await axios.get(`${baseUrl}/api/events/search?datetime=${unixDate}`, {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  });
                  if (response.status === 200) {
                    setEventDetails(response.data);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }}
            tileClassName={({ date }) => {
              //  console.log(parseInt((new String(date).split(" ")[2])))
              // console.log(typeof new Date().getDate())
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              if (currentDate > date) {
                return "inactive-day";
              } else {
                return "active-day";
              }
            }}
          />
        </div>
        <div>
          <p>{dayjs(EventDateDay).format("dddd, MMMM D [of] YYYY")}</p>
          <div className={eventDetails.length == 0 ? "no-event" : ""}>
            {eventDetails.length == 0 ? (
              <p>No event Available Today</p>
            ) : (
              <>
                <div className='d-img-container'>
                  <img src={picture} alt='picture profile' />
                  <p>Funmilayo maxwell</p>
                </div>
                <div className='dashboard-event-details'>
                  <div>
                    <div className='d-event-info'>
                      <p>Event title</p>
                      <p>{eventDetails[0].title}</p>
                    </div>
                    <div className='d-event-info'>
                      <p>Time</p>
                      <p>10:00 a.m</p>
                    </div>
                  </div>
                  <div>
                    <div className='d-event-info'>
                      <p>Location</p>
                      <p>{eventDetails[0].venue}</p>
                    </div>
                    <div className='d-event-info'>
                      <p>Price</p>
                      <p>N50,000</p>
                    </div>
                  </div>
                  <div>
                    <div className='d-event-info'>
                      <p>Date</p>
                      <p>{dayjs(eventDetails[0].datetime).format("D [of] MMMM, YYYY")}</p>
                    </div>
                  </div>
                  <div>
                    <div className='d-event-info'>
                      <p>Description</p>
                      <p>I need a musician that is well vocal, and can hit a very high note and has a good voice control</p>
                    </div>
                  </div>
                  <div>
                    <div className='d-event-info'>
                      <p>Phone number</p>
                      <p>08061403147</p>
                    </div>
                  </div>
                  <div>
                    <div className='d-event-info'>
                      <p>E-mail</p>
                      <p>fatimah@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div onClick={handleToClipboard} className={isCopied ? "copy-details copied" : "copy-details"}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
                    <path
                      d='M25.5 30H11.5C10.3065 30 9.16193 29.5259 8.31802 28.682C7.47411 27.8381 7 26.6935 7 25.5V11.5C7 10.3065 7.47411 9.16193 8.31802 8.31802C9.16193 7.47411 10.3065 7 11.5 7H25.5C26.6935 7 27.8381 7.47411 28.682 8.31802C29.5259 9.16193 30 10.3065 30 11.5V25.5C30 26.6935 29.5259 27.8381 28.682 28.682C27.8381 29.5259 26.6935 30 25.5 30Z'
                      fill='#1C5D99'
                    />
                    <path
                      d='M10 5H24.7425C24.4314 4.12328 23.8566 3.3643 23.097 2.82718C22.3374 2.29006 21.4303 2.00112 20.5 2H6.5C5.30653 2 4.16193 2.47411 3.31802 3.31802C2.47411 4.16193 2 5.30653 2 6.5V20.5C2.00112 21.4303 2.29006 22.3374 2.82718 23.097C3.3643 23.8566 4.12328 24.4314 5 24.7425V10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5Z'
                      fill='#1C5D99'
                    />
                  </svg>
                  {!isCopied ? <p>Copy details</p> : <p>copied</p>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole
          component={component}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
    </div>
  );
}
