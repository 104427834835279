import * as yup from "yup";

export const initialValuesSchema = yup.object().shape({
  Title: yup.string().required("*Title field is required"),
  Date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "*Date must be in the format YYYY-MM-DD")
    .required("*Date is required"),
  Type: yup.string().required("*This field is required"),
  Description: yup.string().required("*Description is required"),
  Doc: yup.string()
});

export default initialValuesSchema;

export const inputFieldSchema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("*"),
  phonenumber: yup.number().required("*"),
  firstname: yup.string().required("*"),
  lastname: yup.string().required("*")
  // jobCategory: yup.string()
});

export interface FormValues {
  Title: string;
  Date: string;
  Type: string;
  Description: string;
  Image: string;
}

export const convertFileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
export const TypeOptions = [
  "Planner refused to pay up balance of the fee",
  "Venue wasn’t well communicated by the planner",
  "Planner refused to pay up agreed fee",
  "Logistics wasn’t provided by the planner as agreed",
  "Offered more service than discussed",
  "Date of event was changed without prior notice",
  "Damaged fees were not paid",
  "Overtime fees not paid",
  "Others"
];
export const JobOptions = ["Caterer", "Job 2", "Job 3"];
export const LocationOptions = ["Location 1", "Location 2", "Location 3"];
