import React, { useEffect } from "react";
import { useState } from "react";
import "./VendorDetailsStyles.css";
import PlannerHeader from "../Header/Header";
import SwitchRole from "../../SwitchRole";
import { useMyContext } from "../../../../ContextApi";
import { useParams } from "react-router-dom";
import axios from "axios";
import PlannerSidebarComponent from "../PlannerSidebar";

const baseUrl = process.env.REACT_APP_API_URL;

const VendorDetails = () => {
  const token = sessionStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("");
  const { setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
    jobCategory: "",
    socialMediaHandle: "",
    homeAddress: "",
    profilePicture: ""
  });
  const { vendorId } = useParams();

  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  const getUser = async () => {
    const endpoint = `${baseUrl}/api/users/${vendorId}`;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status == 200) {
        setVendorDetails({
          _id: response.data.user._id,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          username: response.data.user.username,
          email: response.data.user.email,
          phoneNumber: response.data.user.phoneNumber,
          jobCategory: response.data.user.jobCategory,
          socialMediaHandle: response.data.user.socialMediaHandle,
          homeAddress: response.data.user.homeAddress,
          profilePicture: response.data.user.profilePicture
        });
      } else {
        setVendorDetails({
          _id: "",
          firstName: "",
          lastName: "",
          username: "",
          email: "",
          phoneNumber: "",
          jobCategory: "",
          socialMediaHandle: "",
          homeAddress: "",
          profilePicture: ""
        });
      }
    } catch (error) {
      setVendorDetails({
        _id: "",
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        phoneNumber: "",
        jobCategory: "",
        socialMediaHandle: "",
        homeAddress: "",
        profilePicture: ""
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className='vendor-details-page' onClick={handleClick}>
      <PlannerSidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      {vendorDetails._id ? (
        <div className='vendor-details'>
          <PlannerHeader title='Vendor details' />
          <div className='vendor-details-main'>
            <div className='vendor-details-heading-box'>
              <div className='vendor-details-heading-box-top'>
                <img src={!vendorDetails.profilePicture ? "/profilePic.png" : vendorDetails.profilePicture} alt='profile-pic' />
                <div className='vendor-details-heading'>
                  <p className='vendor-name'>{`${vendorDetails.firstName} ${vendorDetails.lastName}`}</p>
                  <p className='vendor-type'>{vendorDetails.jobCategory}</p>
                </div>
              </div>
              <div className='vendor-details-rating'>
                <img src='/star-icon.svg' alt='star-icon' />
                <p>5.0</p>
              </div>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>Phone number</p>
              <p>{vendorDetails.phoneNumber}</p>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>E-mail</p>
              <p>{vendorDetails.email}</p>
            </div>
            <div className='vendor-detail'>
              <p className='vendor-label'>Instagram handle</p>
              <p>{`@${vendorDetails.socialMediaHandle}`}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex justify-center flex-col items-center flex-1 h-full'>
          <p className='text-2xl text-[#1C5D99]'>vendor details are not available yet</p>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
};

export default VendorDetails;
