import React from "react";
import dayjs from "dayjs"

interface transactionProp{
    description: string,
    date: string,
    amount: number
}


export default function Transaction({description,date, amount}:transactionProp){

    function formatDate(date: string){
        const formattedDate = dayjs(date).format('MMM D, YYYY')
        return formattedDate
    }


    return (
        <div className='transaction'>
            <div>
                {
                description === "credit"
                ?
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'>
                    <path
                        d='M1.85616 1.45319C1.85638 1.11322 1.99721 0.787236 2.24772 0.546842C2.49822 0.306447 2.83791 0.171301 3.19218 0.171089L13.9055 0.171089C14.2597 0.171301 14.5994 0.306447 14.8499 0.546842C15.1004 0.787236 15.2413 1.11322 15.2415 1.45319L15.2415 11.7342C15.2291 12.0664 15.0829 12.3811 14.8338 12.612C14.5846 12.843 14.2519 12.9723 13.9055 12.9727C13.5591 12.9723 13.2263 12.843 12.9772 12.612C12.728 12.3811 12.5819 12.0664 12.5695 11.7342L12.5695 4.54958L3.19218 13.5485C2.94147 13.7891 2.60144 13.9242 2.24688 13.9242C1.89233 13.9242 1.5523 13.7891 1.30159 13.5485C1.05089 13.3079 0.910042 12.9816 0.910042 12.6413C0.910042 12.3011 1.05089 11.9748 1.30159 11.7342L10.6789 2.73529L3.19218 2.73529C2.83791 2.73508 2.49822 2.59993 2.24772 2.35954C1.99721 2.11914 1.85639 1.79316 1.85616 1.45319Z'
                        fill='#108C1C'
                    />
                </svg>
                :
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14' fill='none'>
                    <path
                        d='M13.5339 12.1363C13.5269 12.465 13.3842 12.7775 13.1372 13.0049C12.8902 13.2324 12.559 13.3563 12.2165 13.3495L1.85703 13.1361C1.51448 13.1289 1.1887 12.9914 0.951254 12.754C0.713812 12.5165 0.584126 12.1985 0.590682 11.8698L0.795395 1.92839C0.814018 1.60739 0.961596 1.30602 1.20711 1.08764C1.45263 0.869262 1.77696 0.750868 2.11194 0.757344C2.4469 0.764664 2.76608 0.896309 3.0024 1.12461C3.23872 1.35292 3.37377 1.6601 3.37916 1.98159L3.2361 8.92887L12.4828 0.413948C12.73 0.186298 13.0615 0.0623719 13.4044 0.0694317C13.7472 0.0764909 14.0733 0.213957 14.311 0.451593C14.5486 0.689228 14.6783 1.00756 14.6715 1.33657C14.6647 1.66558 14.5221 1.97831 14.2748 2.20596L5.02811 10.7209L12.2675 10.87C12.6101 10.8772 12.9359 11.0147 13.1733 11.2521C13.4107 11.4895 13.5404 11.8076 13.5339 12.1363Z'
                        fill='#1C5D99'
                    />
                </svg>
                }
                <p>{description === "credit" ? "Top up" : "Withdrawal"}</p>
            </div>
            <div>
                <div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
                        <path
                          d='M16 5.97192C16.0001 7.05298 15.6803 8.10989 15.0809 9.00948C14.4815 9.90907 13.6292 10.6111 12.6316 11.0271V11.0254C12.6316 9.12669 11.8774 7.30575 10.5351 5.96316C9.19271 4.62057 7.37208 3.86631 5.47368 3.86631H5.472C5.95942 2.69873 6.83603 1.73598 7.95285 1.14169C9.06967 0.547394 10.3578 0.358221 11.5983 0.606321C12.8388 0.854421 13.9551 1.52449 14.7575 2.50263C15.56 3.48078 15.999 4.70666 16 5.97192ZM3.78947 0.918444C2.89611 0.918444 2.03934 1.27339 1.40764 1.9052C0.775939 2.537 0.421053 3.39392 0.421053 4.28743V5.5508H2.10526V4.28743C2.10526 3.84067 2.28271 3.41222 2.59856 3.09631C2.91441 2.78041 3.34279 2.60294 3.78947 2.60294H5.05263V0.918444H3.78947ZM13.8947 11.4465V12.7099C13.8947 13.1566 13.7173 13.5851 13.4014 13.901C13.0856 14.2169 12.6572 14.3944 12.2105 14.3944H10.9474V16.0789H12.2105C13.1039 16.0789 13.9607 15.7239 14.5924 15.0921C15.2241 14.4603 15.5789 13.6034 15.5789 12.7099V11.4465H13.8947ZM5.47368 16.5C6.9254 16.5 8.31765 15.9232 9.34416 14.8965C10.3707 13.8698 10.9474 12.4774 10.9474 11.0254C10.9474 9.57344 10.3707 8.18096 9.34416 7.15427C8.31765 6.12759 6.9254 5.5508 5.47368 5.5508C4.02197 5.5508 2.62972 6.12759 1.6032 7.15427C0.57669 8.18096 0 9.57344 0 11.0254C0 12.4774 0.57669 13.8698 1.6032 14.8965C2.62972 15.9232 4.02197 16.5 5.47368 16.5ZM5.47368 8.91978L7.57895 11.0254L5.47368 13.131L3.36842 11.0254L5.47368 8.91978Z'
                          fill='#1C5D99'
                        />
                    </svg>
                    <span>{amount}</span>
                </div>
                <span>{formatDate(date)}</span>
            </div>
        </div>
    )
}