import React from "react";
import Countdown from "react-countdown";

interface CountdownProps {
  setIsCountDownOver: React.Dispatch<React.SetStateAction<boolean>>;
  isCountDownOver: boolean;
  countdownKey: number;
}

function CountDownComponent({ setIsCountDownOver, isCountDownOver, countdownKey }: CountdownProps) {
  const renderer = ({ minutes, seconds, completed }: { minutes: number; seconds: number; completed: boolean }) => {
    if (completed) {
      setIsCountDownOver(true);
      return <span>{`0${minutes}:0${seconds}`}</span>;
    } else {
      if (seconds < 10) {
        return <span>{`0${minutes}:0${seconds}`}</span>;
      } else {
        return <span>{`0${minutes}:${seconds}`}</span>;
      }
    }
  };
  const renderer2 = ({ minutes, seconds, completed }: { minutes: number; seconds: number; completed: boolean }) => {
    if (completed) {
      return <span>{`0${minutes}:0${seconds}`}</span>;
    } else {
      return <span>{`0${minutes}:0${seconds}`}</span>;
    }
  };
  return (
    <>
      {!isCountDownOver ? (
        <Countdown key={countdownKey} date={Date.now() + 60000} renderer={renderer} />
      ) : (
        <Countdown key={countdownKey} date={Date.now()} renderer={renderer2} />
      )}
    </>
  );
}

export default React.memo(CountDownComponent, (prevProps, nextProps) => {
  return (
    prevProps.setIsCountDownOver === nextProps.setIsCountDownOver &&
    prevProps.isCountDownOver === nextProps.isCountDownOver &&
    prevProps.countdownKey === nextProps.countdownKey
  );
});
