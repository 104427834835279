import React, { useState } from "react";
import ReportEventComponent from "../../ReportEventComponent";
import SidebarComponent from "../../SidebarComponent";
//import SupportVComponent from "../../SupportVComponent";
import SwitchRole from "../../SwitchRole";
import { useMyContext } from "../../../../ContextApi";

export default function ReportEvent() {
  const { setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("orderrequest");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <ReportEventComponent />
      {isSwitchUserModalOpen && (
        <SwitchRole isSwitchUserModalOpen={isSwitchUserModalOpen} setIsSwitchUserModalOpen={setIsSwitchUserModalOpen} component='planner' />
      )}
    </div>
  );
}
