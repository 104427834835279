import React, { useEffect, useState } from "react";
import "./NotificationsStyles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { notificationTimeDifference } from "../../../../utils/reuseableFunctions";

const baseUrl = process.env.REACT_APP_API_URL;

const ENDPOINT = `${baseUrl}/api/proposals/planner`;

const Notification = ({ setNoOfProposal }: { setNoOfProposal: React.Dispatch<React.SetStateAction<number>> }) => {
  const token = sessionStorage.getItem("token");
  const [proposals, setProposals] = useState([]);
  const navigate = useNavigate();
  async function getProposals() {
    try {
      const response = await axios.get(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposals(response.data.proposals);
        setNoOfProposal(response.data.no_of_proposals);
      } else {
        setProposals([]);
        setNoOfProposal(0);
      }
    } catch (error) {
      setProposals([]);
      setNoOfProposal(0);
    }
  }

  useEffect(() => {
    getProposals();
  }, []);
  return (
    <div className={proposals.length > 0 ? "notification" : "nofitication no-notifications"}>
      {/* <p className='period'>{period}</p> */}
      {proposals.length > 0 ? (
        proposals.map(({ _id, createdAt, vendor, proposedAmount, eventId }) => (
          <div
            className='notification-offer-container'
            key={_id}
            onClick={() => {
              navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
            }}
          >
            <div className='notification-offer'>
              <p className=' flex items-center text-lg font-semibold'>
                <span></span>Proposal
              </p>
              <p className='notification-time'>{notificationTimeDifference(createdAt)}</p>
            </div>
            <p className='notification-message'>{`${vendor} offering ${proposedAmount} for an event`}</p>
          </div>
        ))
      ) : (
        <p>No Notifications yet</p>
      )}
    </div>
  );
};
export default Notification;
