import React from "react";
import axios from "axios";
import "./AcceptOfferStyles.css";

interface acceptModalProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  openSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setVendorName: React.Dispatch<React.SetStateAction<string>>;
  setProposedAmount: React.Dispatch<React.SetStateAction<string>>;
  setIsProposalAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  vendorName: string;
  proposedAmount: string;
  proposalId: string;
}

const baseUrl = process.env.REACT_APP_API_URL;

const AcceptOffer = ({
  closeModal,
  openSuccessModal,
  vendorName,
  proposedAmount,
  setVendorName,
  setProposedAmount,
  proposalId,
  setIsProposalAccepted
}: acceptModalProps) => {
  const token = sessionStorage.getItem("token");

  const acceptProposals = async () => {
    try {
      const response = await axios.patch(
        `${baseUrl}/api/proposal/accept/${proposalId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status === 200) {
        closeModal(false);
        setVendorName("");
        setProposedAmount("");
        setIsProposalAccepted(true);
        openSuccessModal(true);
      } else {
        openSuccessModal(false);
      }
    } catch (error) {
      openSuccessModal(false);
    }
  };

  return (
    <>
      <div className='planner-modal'>
        <h1 className='accept-offer-title'>
          Are you sure you want to accept {vendorName}’s offer going for {proposedAmount}?
        </h1>
        <div className='accept-offer-buttons'>
          <button
            onClick={() => {
              closeModal(false);
              setVendorName("");
              setProposedAmount("");
            }}
          >
            No
          </button>
          <button onClick={acceptProposals}>Yes</button>
        </div>
      </div>
      <div className='planner-modal-overlay' onClick={() => closeModal(false)}></div>
    </>
  );
};

export default AcceptOffer;
