import React, { useState } from "react";
import Group1 from "../../../assets/illustration1.png";
import Group2 from "../../../assets/illustration2.png";
import Group3 from "../../../assets/illustration3.png";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import partyPal from "../../../assets/Partypal-light.svg";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { AppRoutes, PlannerRoutes } from "../../../utils/AppRoutes";
import { useSwipeable, SwipeableHandlers } from "react-swipeable";

interface CustomJwtPayload extends JwtPayload {
  user: {
    dateRegisterdAsVendor: string;
  };
}

export default function OnBoardingComponent() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [count, setCount] = useState(1);

  const handlers: SwipeableHandlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData);
    },
    onSwipedLeft: () =>
      setCount((prev) => {
        if (prev >= 3) {
          return 3;
        }
        return prev + 1;
      }),
    onSwipedRight: () =>
      setCount((prev) => {
        if (prev <= 1) {
          return 1;
        }
        return prev - 1;
      })
    // Add other configuration options as needed
  });

  function handleClick() {
    setCount((prev) => {
      if (prev <= 0) {
        return 0;
      }
      return prev - 1;
    });
  }

  function handleClick2() {
    navigate(AppRoutes.SIGNUP);
  }
  if (token) {
    const decoded: CustomJwtPayload = jwtDecode(token);
    if (decoded.user.dateRegisterdAsVendor) {
      return <Navigate to={AppRoutes.V_DASH} />;
    } else {
      return <Navigate to={PlannerRoutes.HOME} />;
    }
  }
  return (
    <div className='onboarding'>
      <div className='onboarding-splash'>
        {/* <p>Partypal</p> */}
        <img src={partyPal} alt='logo' />
      </div>
      {count > 1 && (
        <svg onClick={handleClick} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M16.6195 2.98998C16.5034 2.87357 16.3655 2.78121 16.2136 2.71819C16.0617 2.65518 15.8989 2.62274 15.7345 2.62274C15.5701 2.62274 15.4073 2.65518 15.2554 2.71819C15.1036 2.78121 14.9656 2.87357 14.8495 2.98998L6.53951 11.3C6.44681 11.3925 6.37326 11.5024 6.32308 11.6234C6.2729 11.7443 6.24707 11.874 6.24707 12.005C6.24707 12.1359 6.2729 12.2656 6.32308 12.3866C6.37326 12.5076 6.44681 12.6175 6.53951 12.71L14.8495 21.02C15.3395 21.51 16.1295 21.51 16.6195 21.02C17.1095 20.53 17.1095 19.74 16.6195 19.25L9.37951 12L16.6295 4.74998C17.1095 4.26998 17.1095 3.46998 16.6195 2.98998Z'
            fill='#262626'
          />
        </svg>
      )}
      {count <= 3 && (
        <div className='slider'>
          <div className={count >= 1 ? "active" : ""}></div>
          <div className={count > 1 ? "active" : ""}></div>
          <div className={count > 2 ? "active" : ""}></div>
        </div>
      )}
      {count === 1 ? (
        <div className='swiper-container' {...handlers}>
          <div className='intro1'>
            <div className='intro1-img'>
              <img src={Group1} alt='illustration 1' />
            </div>
            <div className='intro1-container'>
              <p>Welcome to Partypal.</p>
              <p>
                Connect with top Vendors or Event Planners <br />
                through our all-in-one event solution.
              </p>
              <button onClick={() => setCount(2)} className='intro1-btn first-btn'>
                <span>Continue</span>
                <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                    fill='white'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : count === 2 ? (
        <div className='swiper-container' {...handlers}>
          <div className='intro2'>
            <div className='intro2-img'>
              <img src={Group2} alt='illustration2' />
            </div>
            <div className='intro2-container'>
              <p>
                Choose your role and
                <br />
                switch anytime!
              </p>
              <p>
                Connect with top Vendors or Event Planners
                <br />
                through our all-in-one event solution.
              </p>
              <button onClick={() => setCount(3)} className='intro1-btn second-btn'>
                <span>Continue</span>
                <svg className='moveleft' xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M13.2686 4.60444C13.5686 4.30449 13.9755 4.13599 14.3998 4.13599C14.8241 4.13599 15.231 4.30449 15.531 4.60444L22.731 11.8044C23.031 12.1045 23.1995 12.5114 23.1995 12.9356C23.1995 13.3599 23.031 13.7668 22.731 14.0668L15.531 21.2668C15.2292 21.5583 14.8251 21.7196 14.4056 21.7159C13.986 21.7123 13.5847 21.544 13.2881 21.2474C12.9914 20.9507 12.8232 20.5494 12.8195 20.1299C12.8159 19.7104 12.9772 19.3062 13.2686 19.0044L17.5998 14.5356H2.3998C1.97546 14.5356 1.56849 14.3671 1.26843 14.067C0.968376 13.767 0.799805 13.36 0.799805 12.9356C0.799805 12.5113 0.968376 12.1043 1.26843 11.8043C1.56849 11.5042 1.97546 11.3356 2.3998 11.3356H17.5998L13.2686 6.86684C12.9687 6.5668 12.8001 6.15991 12.8001 5.73564C12.8001 5.31138 12.9687 4.90449 13.2686 4.60444Z'
                    fill='white'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : count === 3 ? (
        <div className='swiper-container' {...handlers}>
          <div className='intro3'>
            <div className='intro3-img'>
              <img src={Group3} alt='illustration3' />
            </div>
            <div className='intro3-container'>
              <p>Plan your event with ease</p>
              <p>Event creation made effortless, plan with ease!</p>
              <button onClick={handleClick2} className='intro1-btn third-btn'>
                <span>Sign up</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {count <= 3 && (
        <p className='account'>
          Have an account? <NavLink to='/login'>Log in</NavLink>
        </p>
      )}
    </div>
  );
}
