import React, { useEffect } from "react";
import Cookies from "js-cookie";
import LogOutModal from "../common/LogOutModal";
import { useMyContext } from "../../ContextApi";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useSwipeable, SwipeableHandlers } from "react-swipeable";

interface CustomJwtPayload extends JwtPayload {
  user: {
    dateRegisterdAsVendor: string;
    jobCategory: string;
    _id: string;
    email: string
  };
}

export function MainLayout({ children }: any) {
  const { isLogOutModalOpen, setIsLogOutModalOpen, setIsMobileSidebarOpen } = useMyContext();
  const token = Cookies.get("token");
  if (token) {
    sessionStorage.setItem("token", token);
    const decoded: CustomJwtPayload = jwtDecode(token);
    sessionStorage.setItem("jobCategory", decoded.user.jobCategory);
    sessionStorage.setItem("_id", decoded.user._id);
    sessionStorage.setItem("email", decoded.user.email)
  }

  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: () => setIsMobileSidebarOpen(false),
    onSwipedRight: () => setIsMobileSidebarOpen(true)
  });

  
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const expiryTime = Cookies.get("expiryTime");

      if (expiryTime && new Date(expiryTime) < new Date()) {
        Cookies.remove("token");
        Cookies.remove("expiryTime");
      }
    }
  });

  return (
    <div {...handlers}>
      {children}
      {isLogOutModalOpen && (
        <LogOutModal
          isLogOutModalOpen={isLogOutModalOpen}
          setIsLogOutModalOpen={setIsLogOutModalOpen}
          title='Are you sure you want  log out of your account?'
          btnText1='Log out'
          btnText2='Cancel'
        />
      )}
    </div>
  );
}

export default MainLayout;
