import React from "react";
import HomeComponent from "../../pages/home/HomeComponent";

export function HomeScreen() {
  return (
    <>
      <HomeComponent />
    </>
  );
}

export default HomeScreen;
