import React from "react";
import { useNavigate } from "react-router-dom";

import "../Events/EventStyles.css";
import "./EventStyles.css";

import { AppRoutes } from "../../../../utils/AppRoutes";

import dayjs from "dayjs";

interface EventProps {
  name: string;
  date?: string;
  id: number;
}

const Event: React.FC<EventProps> = ({ name, date, id }) => {
  const formatedDate = dayjs(date).format("DD/MM/YY");
  const navigate = useNavigate();

  const handleNavigation = (id: number) => {
    const eventId = id.toString();
    navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
  };

  return (
    <>
      <div className='planner-event' key={id} onClick={() => handleNavigation(id)}>
        <div className='event-info'>
          <h2 className='title-event'>{name.length >= 30 ? `${name.substring(0, 30)}...` : name}</h2>
          {date && <p>{formatedDate}</p>}
        </div>
        <div className='more-info-btn'>
          <svg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M6.1664 4.76268C6.31676 4.91309 6.40124 5.11707 6.40124 5.32975C6.40124 5.54244 6.31676 5.74641 6.1664 5.89682L1.62901 10.4342C1.55502 10.5108 1.46652 10.5719 1.36866 10.614C1.2708 10.656 1.16555 10.6781 1.05905 10.679C0.952553 10.68 0.846935 10.6597 0.748362 10.6193C0.649789 10.579 0.560234 10.5195 0.484925 10.4442C0.409615 10.3688 0.350058 10.2793 0.309728 10.1807C0.269399 10.0821 0.249105 9.97652 0.25003 9.87002C0.250956 9.76352 0.273082 9.65827 0.315119 9.56042C0.357155 9.46256 0.418259 9.37405 0.494866 9.30006L4.46518 5.32975L0.494866 1.35944C0.34876 1.20816 0.267915 1.00556 0.269742 0.795253C0.27157 0.584949 0.355924 0.383777 0.504637 0.235064C0.65335 0.0863514 0.854522 0.00199604 1.06483 0.0001688C1.27513 -0.00165844 1.47774 0.0791864 1.62901 0.225293L6.1664 4.76268Z'
              fill='white'
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Event;
