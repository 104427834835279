import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "./utils/AppRoutes";
import Cookies from "js-cookie";
// import DashboardPage from "./components/pages/vendor/DashboardPage";
// import PlannerDashboard from "./components/common/Planner/Dashboard/PlannerDashboard";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute3: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = sessionStorage.getItem("token");
  const currentRole = Cookies.get("currentRole");
  if (!token) {
    return <Navigate to={AppRoutes.LOGIN} />;
  } else {
    if (currentRole == "planner") {
      return <>{children}</>;
    } else {
      return <Navigate to={AppRoutes.V_DASH} />;
    }
  }
};

export default ProtectedRoute3;
